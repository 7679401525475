<h2 class="font-medium" mat-dialog-title><strong>{{action}} Webinar</strong></h2>
<mat-dialog-content *ngIf="action != 'Eliminar'; else elseTemplate">
    <form #userForm="ngForm">
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex="40" fxFlex.gt-md="40">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="titulo" name="titulo" [(ngModel)]="local_data.nombre" placeholder="Título">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="10" fxFlex.gt-md="10">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Fecha publicacion" name="fechaPubli" [max]="maxDate" [(ngModel)]="local_data.fechaPubli">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker startView="year"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="25" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="vimeo" name="vimeo" [(ngModel)]="local_data.video" placeholder="Id Vimeo">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="25" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="passVimeo" name="passVimeo" [(ngModel)]="local_data.passVimeo" placeholder="Contraseña">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100">
                <div class="m-r-15 m-l-15">
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Texto webinar</mat-card-title>
                            <quill-editor name="texto" required [(ngModel)]="local_data.texto"></quill-editor>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

        </div>
        <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!userForm.valid">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </form>
</mat-dialog-content>
<ng-template #elseTemplate>
    <p>¿Esta seguro que desa eliminar a <b>{{local_data.nombre}}</b> ?</p>
    <div mat-dialog-actions align="end" class="pt-3">
        <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </div>
</ng-template>