import { Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-webinar-form',
  templateUrl: './webinar-form.component.html',
  styles: [
  ]
})
export class WebinarFormComponent {

  action: string;
  local_data: any;
  selectedImage: any = '';
  documentos: any[] = [];
  
  constructor(
    
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<WebinarFormComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    

  }


  doAction(): void {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  

}
