import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-burofax',
  templateUrl: './burofax.component.html',
  styles: []
})
export class BurofaxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openBurofax(){
    window.open('https://www.seur.com/envio-notificacion-paso1.do;jsessionid=Yf2hXqcWKGQ8fqv4rbhMzY0Hhyrsx91dnp2YPMV47082nqJdqv2g!-1472805068!1470815478924', '_blank');
  }

}
