<h2 class="font-medium" mat-dialog-title><strong>{{action}} Mailing</strong></h2>

<mat-dialog-content *ngIf="action != 'Eliminar'; else elseTemplate" style="display:contents">

    <form #userForm="ngForm">
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex="70" fxFlex.gt-md="70">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="titulo" name="titulo" [(ngModel)]="local_data.titulo" placeholder="Título">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="30" fxFlex.gt-md="30">
                <button mat-button (click)="addDoc()" mat-flat-button color="warn">Añadir doc</button>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100">
                <div class="m-r-15 m-l-15">
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Contenido mailing</mat-card-title>
                            <quill-editor name="cuerpo" [styles]="{height: '580px', width: '700px'}" required [(ngModel)]="local_data.cuerpo"></quill-editor>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div fxFlex="100" fxFlex.gt-md="100">
                <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!userForm.valid">{{action}}</button>
                <button mat-button (click)="closeDialog()">Cancel</button>
            </div>
        </div>
    </form>

</mat-dialog-content>
<ng-template #elseTemplate>
    <p>¿Esta seguro que desa eliminar a <b>{{local_data.nombre}}</b> ?</p>
    <div mat-dialog-actions align="end" class="pt-3">
        <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </div>
</ng-template>