<div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
    <div class="login-registerAdmin-box">

        <mat-card class="m-t-10">
            <mat-card-content>
                <form id="loginform">
                    <div class="text-center">
                        <img alt="homepage" src="assets/images/logoCompleto.png" (dblclick)="access()">

                    </div>
                    <div *ngIf="msg" class="bg-danger p-10 text-white">{{ msg }}</div>
                    <div fxLayout="row wrap" *ngIf="!twofa">
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field>
                                <input matInput placeholder="email" required="" #u1>
                            </mat-form-field>
                        </div>
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field>
                                <input matInput type="password" placeholder="Contraseña" required="" #p2>
                            </mat-form-field>
                        </div>
                        <!-- col half-->
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="50">
                            <mat-checkbox color="warn">Recuerdame</mat-checkbox>
                        </div>
                        <!-- col half-->
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" class="text-right">

                        </div>
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" (click)="check(p2.value, u1.value)" type="button">Login Colegio</button>
                        </div>
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="text-center">

                        </div>
                    </div>
                    <div fxLayout="row wrap" *ngIf="twofa">

                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field>
                                <input matInput type="password" placeholder="Código de verificación" #p3>
                            </mat-form-field>
                        </div>
                        <!-- col half-->
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" class="text-right">

                        </div>
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" (click)="check(p3.value)" type="button">Login Colegio</button>
                        </div>
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="text-center">

                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>