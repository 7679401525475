import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ColegiadoResp, Colegiado } from '../../models/colegiado.model';


@Injectable({
  providedIn: 'root'
})
export class ColegiadoService {

  page: number = 0;

  constructor(private dataService: DataService) { }

  getSolicitudes(buscar?: any){
    this.page ++;
    return new Promise<ColegiadoResp>(resolve => {
      this.dataService.getData(`colegiado/solicitudes?àge=${this.page}&buscar=${buscar}`).then((resp: ColegiadoResp) => {
        resolve(resp);
      });
    });
  }

  getColegiados(ejercientes: boolean, buscar?: any){
    this.page ++;
    return new Promise<ColegiadoResp>(resolve => {
      this.dataService.getData(`colegiado/ejerciente/${ejercientes}?page=${this.page}&buscar=${buscar}`).then((resp: ColegiadoResp) => {
        resolve(resp);
      });
    });
  }
  getColegiadosExport(ejercientes: boolean){

    return new Promise<ColegiadoResp>(resolve => {
      this.dataService.getData(`colegiado/export`).then((resp: ColegiadoResp) => {
        resolve(resp);
      });
    });
  }

  setColegiado(colegiado: Colegiado){
    return new Promise<ColegiadoResp>(resolve => {
      this.dataService.setData('colegiado', colegiado).then((resp: ColegiadoResp) => {
        resolve(resp);
      });
    });
  }

  solicitaAcceso(dni: string, nTerritorial: number){
    return new Promise<any>(resolve => {
      this.dataService.setData(`colegiado/acceso`, {dni, nTerritorial}).then(resp => {
        resolve(resp);
      });
    });
  }

  resetPass(email: string){
    return new Promise<any>(resolve => {
      this.dataService.setData('colegiado/resetPass', {email}).then(resp => {
        resolve(resp);
      });
    });
  }

  udateColegiado(colegiado: Colegiado){
    return new Promise<ColegiadoResp>(resolve => {
      this.dataService.updateData(`colegiado/${colegiado._id}`, colegiado).then((resp: ColegiadoResp) => {
        resolve(resp);
      });
    });
  }

  delColegiado(colegiado: Colegiado) {
    return new Promise<ColegiadoResp>(resolve => {
      this.dataService.delData(`colegiado/${colegiado._id}`).then((resp: ColegiadoResp) => {
        resolve(resp);
      });
    });
  }

  changePasswdColegiado(password: string){
    return new Promise<any>(resolve => {
      this.dataService.setData(`colegiado/changePasswd`, {password}).then((resp: any) => {
        resolve(resp);
      });
    });1
  }
}
