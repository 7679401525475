import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { AjustesCorreoRsp, AjusteCorreo } from '../../models/setting.model';


@Injectable({
  providedIn: 'root'
})
export class AjustesCorreoService {

  constructor(private dataService: DataService) { }

  getAjustesCorreo(){
    return new Promise<AjustesCorreoRsp>(resolve => {
      this.dataService.getData('ajustes-correo').then((resp: AjustesCorreoRsp) => {
        resolve(resp);
      });
    });
  }

  setAjustesCorreo(ajustesCorreo: AjusteCorreo){
    return new Promise<AjustesCorreoRsp>(resolve => {
      this.dataService.setData('ajustes-correo', ajustesCorreo).then((resp: AjustesCorreoRsp) => {
        resolve(resp);
      });
    });
  }

  updateAjustesCorreo(ajustesCorreo: AjusteCorreo){
    return new Promise<AjustesCorreoRsp>(resolve => {
      this.dataService.updateData(`ajustes-correo/${ajustesCorreo._id}`, ajustesCorreo).then((resp => {
        resolve(resp);
      }));
    });
  }
}
