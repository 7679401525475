<div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
    <div class="login-register-box">
        <mat-card>
            <mat-card-content>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="text-center">
                        <img alt="homepage" src="assets/images/logoCompleto.png">
                        <h4 class="m-t-0">Recuperar contraseña</h4>
                    </div>
                    <p class="text-center font-14">ingrese su email y le enviaremos una nueva contraseña de acceso.</p>

                    <div fxLayout="column" fxLayoutAlign="space-around">
                        <div class="pb-1">
                            <mat-form-field style="width: 100%">
                                <input matInput placeholder="Dirección de Email" type="email" [formControl]="form.controls['email']" [(ngModel)]="email">
                            </mat-form-field>
                            <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" class="text-danger support-text">La direcciónd e correo es necesaria.</small>
                            <small *ngIf="form.controls['email'].errors?.email && form.controls['email'].touched" class="text-danger support-text">La dirección de correo no es válida.</small>
                        </div>
                        <button mat-raised-button color="primary" type="submit" class="btn-block btn-lg m-t-20" [disabled]="!form.valid">Enviar</button>
                    </div>

                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>
