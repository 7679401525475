<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
                        <mat-form-field>
                            <input matInput (keydown.enter)="applyFilter($event.target.value)" placeholder="Buscar mailing ...">
                        </mat-form-field>
                        <div>
                            <button mat-button (click)="loadData()">
                                <mat-icon style="color: #3239f5">autorenew</mat-icon>
                                Reload
                            </button>
                        </div>
                    </div>
                    <div fxFlex.gt-md="75" class="text-right" *ngIf="permiso !=='COLEG'">
                        <button mat-raised-button (click)="openDialog('Nuevo',{})" color="primary">Nuevo
                            mailing</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

                        <ng-container matColumnDef="titulo">
                            <th mat-header-cell *matHeaderCellDef> Título </th>
                            <td mat-cell *matCellDef="let element"> {{element.titulo.toUpperCase()}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fecha">
                            <th mat-header-cell *matHeaderCellDef> Fecha </th>
                            <td mat-cell *matCellDef="let element"> {{element.fecha | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="destinatarios">
                            <th mat-header-cell *matHeaderCellDef> Destinatarios </th>
                            <td mat-cell *matCellDef="let element"> {{element.destinatarios}} Colegiados</td>
                        </ng-container>
                        <ng-container matColumnDef="enviados">
                            <th mat-header-cell *matHeaderCellDef> Enviados </th>
                            <td mat-cell *matCellDef="let element"> {{element.enviado || 0}} Colegiados</td>
                        </ng-container>
                        <ng-container matColumnDef="proceso">
                            <th mat-header-cell *matHeaderCellDef> En Proceso </th>
                            <td mat-cell *matCellDef="let element">
                                <label *ngIf="!element.proceso" style="color: #fc0303">NO</label>
                                <label *ngIf="element.proceso" style="color: #2eb350">SI</label>
                            </td>

                        </ng-container>
                        <ng-container matColumnDef="finalizado">
                            <th mat-header-cell *matHeaderCellDef> Finalizado </th>
                            <td mat-cell *matCellDef="let element">
                                <label *ngIf="element.finalizado" style="color: #2eb350">SÍ</label>
                                <label *ngIf="!element.finalizado" style="color: #fc0303">NO</label>
                            </td>

                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Acciones </th>
                            <td mat-cell *matCellDef="let element" class="action-link">
                                <button mat-icon-button (click)="openDialog('Clonar',element)" matTooltip="Clonar mailing" [disabled]="element.proceso" class="m-r-10 cursor-pointer">
                                    <mat-icon>launch</mat-icon>
                                </button>
                                <button mat-icon-button (click)="openDialog('Enviar',element)" matTooltip="Envio mailing" [disabled]="element.proceso || element.finalizado" class="m-r-10 cursor-pointer">
                                    <mat-icon>send</mat-icon>
                                </button>
                                <button mat-icon-button (click)="openDialog('Actualiza',element)" matTooltip="Editar mailing" [disabled]="element.proceso" class="m-r-10 cursor-pointer">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="openDialog('Test',element)" matTooltip="Enviar test" [disabled]="element.proceso" class="m-r-10 cursor-pointer">
                                    <mat-icon>publish</mat-icon>
                                </button>
                                <button mat-icon-button (click)="openDialog('Eliminar',element)" matTooltip="Eliminar mailing" [disabled]="element.proceso" class="m-r-10 cursor-pointer">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #paginatoR [length]="length" [pageSize]="10" (page)="pageEvent = getServerData($event)"></mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>