import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { MemoriaResp, Memoria } from '../../models/memoria.model';

@Injectable({
  providedIn: 'root'
})
export class MemoriaService {

  constructor(private dataService: DataService) { }

  page = 0;

  getMemorias(){
    return new Promise<MemoriaResp>(resolve => {
      this.dataService.getData('memoria').then((resp: MemoriaResp) => {
        resolve(resp);
      });
    });
  }

  setMemoria(memoria: Memoria) {
    return new Promise<MemoriaResp>(resolve => {
      this.dataService.setData('memoria', memoria).then((resp: MemoriaResp) => {
        resolve(resp);
      })
    }); 
  }

  delMemoria(memoriaId: string){
    return new Promise<MemoriaResp>(resolve => {
      this.dataService.delData(`memoria/${memoriaId}`).then((resp: MemoriaResp) => {
        resolve(resp);
      });
    });
  }
}
