import { Component, OnInit, Optional, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Usuario } from '../../../models/usuario.model';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styles: []
})
export class UsuarioFormComponent implements OnInit {

  action: string;
  local_data: any;
  selectedImage: any = '';

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<UsuarioFormComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Usuario) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    /* if (this.local_data.fechaNacimiento !== undefined) {
      this.local_data.fechaNacimiento = this.datePipe.transform(new Date(this.local_data.fechaNacimiento), 'yyyy-MM-dd');
    }
    if (this.local_data.ccc){
      this.local_data.iban = this.local_data.ccc.iban || '';
      this.local_data.nc = this.local_data.ccc.nc || '';
    }

    if (this.local_data.fechaSolicitud !== undefined) {
      this.local_data.fechaSolicitud = this.datePipe.transform(new Date(this.local_data.fechaSolicitud), 'yyyy-MM-dd');
    }
    if (this.local_data.imagePath === undefined) {
      this.local_data.imagePath = 'assets/images/users/default.png';
    }*/
  }

  ngOnInit(): void {
    console.log(this.action);
  }

  doAction(): void {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  selectFile(event: any): void {
    if (!event.target.files[0] || event.target.files[0].length === 0) {
      // this.msg = 'You must select an image';
      return;
    }
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      // this.msg = "Only images are supported";
      return;
    }
    // tslint:disable-next-line - Disables all
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    // tslint:disable-next-line - Disables all
    reader.onload = (_event) => {
      // tslint:disable-next-line - Disables all
      this.local_data.avatar = reader.result;
    };
  }


}
