import { Component, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UiService } from '../../services/ui.service';
import globalUser from '../../../environments/globales';
import { MatDialog } from '@angular/material/dialog';
import { MailingFormComponent } from '../../formularios/mailing-form/mailing-form.component';
import { MailingService } from '../../services/mailing.service';
import { Mailing } from '../../../models/mailing.model';

@Component({
  selector: 'app-comunicaciones',
  templateUrl: './comunicaciones.component.html',
  styles: []
})
export class ComunicacionesComponent {

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  pageEvent: any;
  pages: any[] = [0];
  displayedColumns: string[] = ['titulo', 'fecha', 'destinatarios', 'enviados', 'proceso', 'finalizado', 'action'];
  dataSource: MatTableDataSource<Mailing>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  ejercientes: boolean = false;
  length: number = 0;

  permiso = globalUser.permiso;


  constructor(private dialog: MatDialog,
    private mailingService: MailingService,
    private ui: UiService) {
    this.dataSource = new MatTableDataSource();
  }

  ngAfterViewInit(): void {
    this.paginator.pageSize = 10;
    this.loadData();
    this.dataSource.paginator = this.paginator;
  }

  async loadData() {
    this.mailingService.page = 0;
    const resp = await this.mailingService.getMailings();
    this.mailingService.page = 1;
    const resp1 = await this.mailingService.getMailings();
    if (!resp.ok && !resp1.ok) {
      window.alert(resp.error);
      return;
    }
    if (resp.mailings && resp1.mailings) {
      this.dataSource.data = [];
      this.dataSource.data = resp.mailings;
      if (resp1.mailings.length > 0) {
        this.dataSource.data.push(...resp1.mailings);
      }
      this.length = Number(resp.total) || 0;
    }
  }

  openDialog(action: string, obj: any): void {
    let dialogRef: any;
    if (action == 'Enviar') {
      this.envioMailing(obj._id);
    } else if (action == 'Test') {
      this.testMailing(obj._id);
    } else if (action == 'Clonar'){
      obj.action = action;
      delete obj.id;
      dialogRef = this.dialog.open(MailingFormComponent, {
        height: '100%',
        width: '900px',
        data: obj
      });
    } else if (action == 'Eliminar') {
      obj.action = action;
      dialogRef = this.dialog.open(MailingFormComponent, {
        data: obj
      });
    }
    else {
      obj.action = action;
      dialogRef = this.dialog.open(MailingFormComponent, {
        height: '100%',
        width: '900px',
        data: obj
      });

    }
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.event === 'Nuevo') {
        this.addData(result.data);
      } else if (result.event === 'Actualiza') {
        this.updateRowData(result.data);
      } else if (result.event === 'Clonar') {
        this.addData(result.data);
      } else if (result.event === 'Eliminar') {
        this.deleteRowData(result.data);
      }
    });

  }

  async envioMailing(mailingId: string) {
    window.alert('Proceso de envio iniciado');
    const send = await this.mailingService.sendaMailing(mailingId);
    this.loadData();
    if (send.error) {
      window.alert(send.error);
    }
    return;
  }

  async testMailing(mailingId: string) {
    window.alert('Inicio del test, se envia correo al administrador');
    const send = await this.mailingService.testMailing(mailingId);
    this.loadData();
    if (send.error) {
      window.alert(send.error);
    }
    return;
  }

  async addData(data: any) {
    const doc: any = {
      titulo: data.titulo,
      cuerpo: data.cuerpo,
      fecha: data.fecha
    }
    const save = await this.mailingService.setMailing(doc);
    if (!save.ok) {
      window.alert(save.error);
      return;
    }
    this.ui.presentInfo('Documento añadido', 'OK!');
    this.loadData();
  }

  async updateRowData(data: any) {
    const update = await this.mailingService.updateMailing(data);
    if (!update.ok) {
      window.alert(update.error);
      return;
    }
    this.loadData();
  }

  openElement(doc: string) {

  }

  async deleteRowData(data: any) {
    const doc = await this.mailingService.delMailing(data._id);
    if (!doc.ok) {
      window.alert(doc.error);
      return;
    }
    this.dataSource.data = this.dataSource.data.filter((value: any) => {
      return value._id !== data._id;
    });
  }

  async applyFilter(filterValue: string) {
    this.searchText = filterValue;
    this.mailingService.page = 0;
    const resp = await this.mailingService.getMailings(this.searchText);
    this.mailingService.page = 1;
    const resp1 = await this.mailingService.getMailings(this.searchText);
    if (!resp.ok && resp1.ok) {
      window.alert(resp.error);
      return;
    }
    if (resp.mailings && resp1.mailings) {
      this.dataSource.data = [];
      this.dataSource.data = resp.mailings;
      this.dataSource.data.push(...resp1.mailings);
      this.length = Number(resp.total) || 0;
    }
  }


  getServerData(event: PageEvent) {
    if (this.pages.includes(event.pageIndex + 1)) {
      return;
    }
    this.pages.push(event.pageIndex + 1);
    this.mailingService.getMailings().then(d => {
      if (!d.ok) {
        window.alert(d.error);
        return;
      }
      if (d.mailings) {
        this.dataSource.data.push(...d.mailings);
      }
    });
  }
}
