import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import globalUser from '../../../../environments/globales';
import { UsuarioService } from '../../../services/usuario.service';
import { UsuarioFormComponent } from 'src/app/formularios/usuario-form/usuario-form.component';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from 'src/app/services/ui.service';
import { ColegiadoPasswdComponent } from 'src/app/formularios/colegiado-passwd/colegiado-passwd.component';
import { ColegiadoService } from 'src/app/services/colegiado.service';



@Component({
  selector: 'app-vertical-header',
  templateUrl: './vertical-header.component.html',
  styleUrls: []
})



export class VerticalAppHeaderComponent {
  public config: PerfectScrollbarConfigInterface = {};

  public selectedLanguage: any = {
    language: 'Español',
    code: 'es',
    icon: 'es'
  };

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'German',
    code: 'de',
    icon: 'de'
  }];

  nombre: string = globalUser.nombre || '';
  permiso: string = globalUser.permiso || '';
  _id: string = globalUser._id || '';

  constructor(private translate: TranslateService,
    private ususarioService: UsuarioService,
    private colegiadoService: ColegiadoService,
    private router: Router,
    private dialog: MatDialog,
    private usuarioService: UsuarioService,
    private ui: UiService
  ) {
    translate.setDefaultLang('es');
    console.log(this.nombre);
  }

  // tslint:disable-next-line: typedef
  changeLanguage(lang: any) {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  // tslint:disable-next-line: typedef
  exit() {
    // Elimino datos del almacenamiento del navegador
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    // Redirección a login
    this.router.navigate(['/login']);
  }

  async miData() {
    if (globalUser.permiso == 'COLEG') {
      const colegiadoRef = this.dialog.open(ColegiadoPasswdComponent, {
      });
      colegiadoRef.afterClosed().subscribe(async (result) => {
      if (result){
        const passwd: any = await this.colegiadoService.changePasswdColegiado(result);
        if (!passwd.ok) {
          window.alert(passwd.error);
          return;
        }
        this.ui.presentInfo(passwd.message, 'Ok');
      }

      });

    } else {
      const usuario: any = await this.ususarioService.getUsuario(this._id);
      if (!usuario.ok) {
        window.alert(usuario.error);
        return;
      }
      usuario.usuario.action = 'Actualiza';
      const dialogRef = this.dialog.open(UsuarioFormComponent, {
        data: usuario.usuario
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.event != 'Cancel') {
          const newUsuario = await this.usuarioService.udateUsuario(result.data);
          if (!newUsuario.ok) {
            window.alert(newUsuario.error);
            return;
          }
          this.ui.presentInfo('Datos actualizados', 'Ok');
        }
      });

    }

  }
}
