import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-colegiado-passwd',
  templateUrl: './colegiado-passwd.component.html',
  styles: []
})
export class ColegiadoPasswdComponent implements OnInit {

  local_data: any = {};
  constructor(
    private matDialogRef: MatDialogRef<ColegiadoPasswdComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
  }

  doAction(){
    this.matDialogRef.close(this.local_data.password);
  }
  closeDialog(){
    this.matDialogRef.close();
  }

}
