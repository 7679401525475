import { globalUser } from './../../../environments/globales';
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
  permiso?: string;
}

const MENUITEMS: Menu[] = [
  {
    state: 'documentacion',
    name: 'Documentación',
    type: 'sub',
    icon: 'folder',
    permiso: 'ANY',
    badge: [{ type: 'caf', value: '5' }],
    children: [
      {state: 'circulares', name: 'Circulares y actas', type: 'link'},
      {state: 'tablon', name: 'Tablón de anuncios', type: 'link'},
      {state: 'ipc', name: 'I.P.C.', type: 'link'},
      {state: 'legislacion', name: 'Legislación', type: 'link'},
      {state: 'formacion', name: 'Formación', type: 'link'},
    ]
  },
  {
    state: 'asesoria',
    name: 'Asesoría',
    type: 'sub',
    icon: 'school',
    permiso: 'ANY',
    badge: [{ type: 'caf', value: '5' }],
    children: [
      {state: 'lopd', name: 'L.O.P.D.', type: 'link'},
      {state: 'fiscal', name: 'Fiscal', type: 'link'},
      {state: 'laboral', name: 'Laboral', type: 'link'},
      {state: 'juridica', name: 'Juridica', type: 'link'},
      {state: 'tecnica', name: 'Técnica', type: 'link'},

    ]
  },
  {
    state: 'utilidades',
    name: 'Utilidades',
    type: 'sub',
    permiso: 'ANY',
    icon: 'cloud_queue',
    badge: [{ type: 'caf', value: '5' }],
    children: [
      {state: 'fax', name: 'e-Burofax', type: 'link'},
      {state: 'guia', name: 'Mantenimiento edificios', type: 'link'},
      {state: 'icred', name: 'Plataforma ICIRED', type: 'link'},
      {state: 'fwebinar', name: 'Formacion Webinar', type: 'link'},

    ]
  },
  {
    state: 'colegio',
    name: 'Colegio',
    type: 'sub',
    icon: 'people_outline',
    permiso: 'SUPER',
    badge: [{ type: 'caf', value: '3' }],
    children: [
      {state: 'colegiados', name: 'Colegiados Activos', type: 'link'},
      {state: 'solicitudes', name: 'Colegiados Inactivos', type: 'link'},
      {state: 'memorias', name: 'Memorias', type: 'link'},
    ]
  },
  {
    state: 'aCursos',
    name: 'Acreditación a cursos',
    type: 'link',
    icon: 'supervised_user_circle',
    permiso: 'COLEG'
  },
  {
    state: 'cursos',
    name: 'Cursos',
    type: 'link',
    icon: 'supervised_user_circle',
    permiso: 'SUPER'
  },
  {
    state: 'usuarios',
    name: 'Usuarios',
    type: 'link',
    icon: 'person',
    permiso: 'SUPER'
  },
  {
    state: 'comunicaciones',
    name: 'Comunicaciones',
    type: 'link',
    icon: 'alternate_email',
    permiso: 'SUPER'
  },
 /* {
    state: 'economico',
    name: 'Económico',
    type: 'sub',
    icon: 'attach_money',
    permiso: 'SUPER',
    badge: [{ type: 'caf', value: '4' }],
    children: [
      {state: 'deudas', name: 'Deudas', type: 'link'},
      {state: 'pagos', name: 'Pagos', type: 'link'},
      {state: 'remesas', name: 'Remesas', type: 'link'},
      {state: 'estadistica', name: 'Informes', type: 'link'}

    ]
  },*/
  {
    state: 'ajustes',
    name: 'Ajustes',
    type: 'sub',
    icon: 'settings',
    permiso: 'SUPER',
    badge: [{ type: 'caf', value: '1' }],
    children: [
      {state: 'correo', name: 'Correo', type: 'link'},
      //{state: 'coutas', name: 'Cuotas', type: 'link'}
    ]
  }
];

@Injectable()
export class MenuItems {
  permiso: any;
  constructor(){
    this.permiso = globalUser.permiso;
    console.log(this.permiso);
  }
  getMenuitem(): Menu[] {
    return MENUITEMS.filter(m => {
      if (this.permiso == 'COLEG'){
        if (m.permiso == 'ANY' || m.permiso == 'COLEG') {
          return m
        }
      } else if (this.permiso === 'SUPER') {
        if (m.permiso == 'SUPER' || m.permiso == 'ANY'){
          return m
        }
      }
    });
  }
}
