<h2 class="font-medium" mat-dialog-title><strong>{{action}} registro de Memoria</strong></h2>
<mat-dialog-content *ngIf="action != 'Eliminar'; else elseTemplate">
    <form #userForm="ngForm">
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex="50" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="number" matInput required id="anio" name="anio" min="1900" max="9999" [(ngModel)]="local_data.anio" placeholder="Año memoria">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="50" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="nombre" name="nombre" [(ngModel)]="local_data.nombre" placeholder="Nombre">
                    </mat-form-field>
                </div>
            </div>

        </div>
        <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!userForm.valid">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </form>
</mat-dialog-content>
<ng-template #elseTemplate>
    <p>¿Esta seguro que desa eliminar a <b>{{local_data.nombre}}</b> ?</p>
    <div mat-dialog-actions align="end" class="pt-3">
        <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </div>
</ng-template>