<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
                        <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar webinar ...">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-md="75" class="text-right" *ngIf="permiso !=='COLEG'">
                        <button mat-raised-button (click)="openDialog('Nuevo',{})" color="primary">Nuevo webinar</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

                        <ng-container matColumnDef="webinar">
                            <th mat-header-cell *matHeaderCellDef> Webinars </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex">
                                    <button mat-button (click)="openElement(element)">
                                        <img style="height: 45px;" src="../../assets/images/logocaf.png" >
                                    </button>
                                    <div *ngIf="permiso !=='COLEG'">
                                        <button mat-icon-button (click)="openDialog('Actualiza', element)" style="color: green;">
                                            <mat-icon>create</mat-icon>
                                        </button>
                                        <button mat-icon-button (click)="openDialog('Eliminar', element)" style="color: red;">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>

                                    <div class="m-l-15">
                                        <p class="font-medium m-b-0 m-t-0">{{ element.nombre.toUpperCase() }}</p>
                                        <small class="text-muted">{{element.fechaPubli | date}}</small>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #paginatoR [length]="length" pageSize="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons (page)="pageEvent = $event"></mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>