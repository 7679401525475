import { Component } from '@angular/core';
import { AjusteCorreo } from '../../../../models/setting.model';
import { AjustesCorreoService } from '../../../services/ajustes-correo.service';
import { UiService } from '../../../services/ui.service';

@Component({
  selector: 'app-correo',
  templateUrl: './correo.component.html',
  styles: []
})
export class CorreoComponent  {

  ajusteCorreo: AjusteCorreo = {}


  constructor( private ajustesCorreoService: AjustesCorreoService,
               private ui: UiService) {
    this.loadAjustes();
  }



  async loadAjustes(){
    const load = await this.ajustesCorreoService.getAjustesCorreo();

    if (!load.ok){
      window.alert(load.error);
      return;
    }
    if (load.ajuste){
      this.ajusteCorreo = load.ajuste;
    }
  }

  async doAction(){
    let ajustes;
    if (this.ajusteCorreo._id){
      ajustes = await this.ajustesCorreoService.updateAjustesCorreo(this.ajusteCorreo);

    } else {
      ajustes = await this.ajustesCorreoService.setAjustesCorreo(this.ajusteCorreo);
    }
    if (!ajustes.ok){
      window.alert(ajustes.error);
      return;
    }
    this.ui.presentInfo('Ajustes almacenados correctamente', 'OK!');
  }

}
