<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
                        <mat-form-field>
                            <input matInput (keydown.enter)="applyFilter($event.target.value)" placeholder="Buscar curso ...">
                        </mat-form-field>
                        <div>
                            <button mat-button (click)="loadData()">
                              <mat-icon style="color: #3239f5">autorenew</mat-icon>
                              Reload
                          </button>
                        </div>
                    </div>
                    <div fxFlex.gt-md="75" class="text-right" *ngIf="permiso !=='COLEG'">
                        <button mat-raised-button (click)="openDialog('Nuevo',{})" color="primary">Nuevo
                          curso</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

                        <ng-container matColumnDef="titulo">
                            <th mat-header-cell *matHeaderCellDef> Título </th>
                            <td mat-cell *matCellDef="let element"> {{element.titulo.toUpperCase()}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fechaInicio">
                            <th mat-header-cell *matHeaderCellDef> Fecha inicio </th>
                            <td mat-cell *matCellDef="let element"> {{element.fechaInicio | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fechaFin">
                            <th mat-header-cell *matHeaderCellDef> Fecha fin </th>
                            <td mat-cell *matCellDef="let element"> {{element.fechaFin | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef> Modalidad </th>
                            <td mat-cell *matCellDef="let element"> {{element.tipo}}</td>
                        </ng-container>
                        <ng-container matColumnDef="asistentes">
                            <th mat-header-cell *matHeaderCellDef> Asistentes </th>
                            <td mat-cell *matCellDef="let element"> {{element.asistentes}} colegiados
                                <button mat-icon-button (click)="abrirAsistencia(element)" matTooltip="Ver asistentes" class="m-r-10 cursor-pointer">
                                <mat-icon>accessibility_new</mat-icon>
                            </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="proceso">
                            <th mat-header-cell *matHeaderCellDef> En Proceso </th>
                            <td mat-cell *matCellDef="let element">
                                <label *ngIf="!element.enProcesoEnvio" style="color: #fc0303">NO</label>
                                <label *ngIf="element.enProcesoEnvio" style="color: #2eb350">SI</label>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="enviado">
                            <th mat-header-cell *matHeaderCellDef> Enviado </th>
                            <td mat-cell *matCellDef="let element">
                                <label *ngIf="!element.enviado" style="color: #fc0303">NO</label>
                                <label *ngIf="element.enviado" style="color: #2eb350">SI</label>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="enviados">
                            <th mat-header-cell *matHeaderCellDef> Enviado a ...</th>
                            <td mat-cell *matCellDef="let element"> {{element.correosEnviados || 0}} Colegiados</td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Acciones </th>
                            <td mat-cell *matCellDef="let element" class="action-link">
                                <button mat-icon-button (click)="openDialog('Clonar',element)" matTooltip="Clonar mailing" [disabled]="element.enProcesoEnvio" class="m-r-10 cursor-pointer">
                                  <mat-icon>launch</mat-icon>
                              </button>
                                <button mat-icon-button (click)="openDialog('Enviar',element)" matTooltip="Envio mailing" [disabled]="element.enProcesoEnvio || element.finalizado || element.closed" class="m-r-10 cursor-pointer">
                                  <mat-icon>send</mat-icon>
                              </button>
                                <button mat-icon-button (click)="openDialog('Actualiza',element)" matTooltip="Editar mailing" [disabled]="element.enProcesoEnvio || element.closed" class="m-r-10 cursor-pointer">
                                  <mat-icon>edit</mat-icon>
                              </button>
                                <button mat-icon-button (click)="openDialog('Test',element)" matTooltip="Enviar test" [disabled]="element.enProcesoEnvio || element.closed" class="m-r-10 cursor-pointer">
                                  <mat-icon>publish</mat-icon>
                              </button>
                                <button mat-icon-button (click)="openDialog('close',element)" matTooltip="Cerrar curso" [disabled]="element.enProcesoEnvio || element.closed" class="m-r-10 cursor-pointer">
                                  <mat-icon>close</mat-icon>
                              </button>
                                <button mat-icon-button (click)="openDialog('Eliminar',element)" matTooltip="Eliminar mailing" [disabled]="element.enProcesoEnvio || element.closed" class="m-r-10 cursor-pointer">
                                  <mat-icon>delete</mat-icon>
                              </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #paginator [length]="length" [pageSize]="10" (page)="pageEvent = $event"></mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
