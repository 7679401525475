import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UsuarioResp, UsuarioLogin } from '../../models/usuario.model';
import { ColegiadoLogin } from '../../models/colegiado.model';
import globalUser from '../../environments/globales';

@Injectable()
export class LoginService {

  header = {
    token: ''
  };
  constructor(private dataService: DataService) { }


  // tslint:disable-next-line: typedef
  checkusernameandpassword(uname: string, pwd: string) {
    if (uname === 'admin' && pwd === 'admin123') {
      this.dataService.saveStorege('token', 'admin');
      return true;
    } else {
      return false;
    }
  }

  loginUser(email: string, pass: string){
    return new Promise<boolean>(resolve => {
      this.dataService.setData('usuarios/login', {email, password: pass}).then(async (login: UsuarioLogin) => {
        await this.dataService.saveStorege('token', login.token);
        if (login.ok){
          return resolve(true);
        }
        resolve(false);
      });
    });
  }

  validaTwoFactor(email: string, twoFa: string){
    return new Promise<UsuarioResp>(async resolve => {
      this.dataService.setData('usuarios/loginTwoFactor', {email, twoFa}).then(async resp => {
        if (resp.ok){
        await this.dataService.saveStorege('token', resp.token);
        const user = await this.dataService.getData('usuarios/validar/token');
          globalUser._id = user._id;
          globalUser.permiso = user.permiso;
          return resolve(user);
        }
        resolve (resp);
      });
    });
  }

  async verificaLogin(){
    return new Promise<UsuarioResp>(async resolve => {
      if (!localStorage.getItem('token')){
        resolve({
          ok: false,
        });
      }
      const verifica = await  this.dataService.getData('usuarios/validar/token');
      if (!verifica){
        resolve({
          ok: false
        });
      }
      globalUser._id = verifica.usuario._id;
      globalUser.permiso = verifica.usuario.permiso;
      globalUser.nombre = verifica.usuario.nombre;
      resolve(verifica);
    });
  }

  loginColegio(email: string, pass: string){
    return new Promise<any>(resolve => {
      this.dataService.setData('colegiado/login', {email, password: pass}).then(async (login: ColegiadoLogin) => {
        await this.dataService.saveStorege('token', login.token);
        resolve(login.ok)
      });
    });
  }

  validaTwoFactorColegio(email: string, twoFa: string){
    return new Promise<UsuarioResp>(async resolve => {
      this.dataService.setData('colegiado/loginTwoFactor', {email, twoFa}).then(async resp => {
        if (resp.ok){
        await this.dataService.saveStorege('token', resp.token);
        const user = await this.dataService.getData('usuarios/validar/token');
          globalUser._id = user._id;
          globalUser.permiso = user.permiso;
          return resolve(user);
        }
        resolve (resp);
      });
    });
  }

  loguout(){
    this.header.token = '';
    return new Promise(resolve => {
        resolve(this.dataService.delStorage('token') && this.dataService.delStorage('usuario'));
    });
  }

}
