<h2 class="font-medium" mat-dialog-title><strong>{{action}} Usuario</strong></h2>
<mat-dialog-content *ngIf="action != 'Eliminar'; else elseTemplate">
    <form #userForm="ngForm">
        <div class="d-flex align-items-center m-b-15">
            <img class="mat-card-avatar" [src]='local_data.avatar'>
            <button mat-raised-button color="primary" class="m-l-15 input-file-button">
                <input type="file" (change)="selectFile($event)" #fileInput>
            </button>
        </div>
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex="100" fxFlex.gt-md="100">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="name" name="name" [(ngModel)]="local_data.nombre" placeholder="Nombre">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="email" name="email" [(ngModel)]="local_data.email" placeholder="Email">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="password" matInput [required]="action === 'Nuevo'" id="password" name="password" [(ngModel)]="local_data.password" placeholder="Contraseña">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="direccion" name="direccion" [(ngModel)]="local_data.direccion" placeholder="Dirección">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="localidad" name="localidad" [(ngModel)]="local_data.localidad" placeholder="Localidad">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="provincia" name="provincia" [(ngModel)]="local_data.provincia" placeholder="Provincia">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="cp" name="cp" [(ngModel)]="local_data.cp" placeholder="Código postal">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="number" matInput id="telefono" name="telefono" [(ngModel)]="local_data.telefono" placeholder="Teléfono">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field appearance="fill">
                        <mat-label>Permisos</mat-label>
                        <mat-select name="permiso" [(ngModel)]="local_data.permiso" required>
                            <mat-option value="USER">Usuario estandar</mat-option>
                            <mat-option value="ADMIN">Usuario Administrador</mat-option>
                            <mat-option value="SUPER">Super Administrdor</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

        </div>
        <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!userForm.valid">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </form>
</mat-dialog-content>
<ng-template #elseTemplate>
    <p>¿Esta seguro que desa eliminar a <b>{{local_data.nombre}}</b> ?</p>
    <div mat-dialog-actions align="end" class="pt-3">
        <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </div>
</ng-template>
