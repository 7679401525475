<mat-dialog-content>
    <form #userForm="ngForm">
        
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex="100" fxFlex.gt-md="75">
                <h3>Solicitud de acceso</h3>                
                <p>
                    Si ya esta dado de alta como colegiado, puede solicitar acceso a la plataforma del colegio. <br>
                    Para ello solo tiene que ingresar su DNI y el número territorial asignado. <br>
                    <strong>Rcibira un correo con su contraseña</strong>
                </p>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" placeholder="D.N.I" matInput required id="dni" name="dni"
                            [(ngModel)]="dni">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="number" matInput required id="nterritorial" name="nterritorial" [(ngModel)]="nterritorial"
                            placeholder="Número territorial">
                    </mat-form-field>
                </div>
            </div>

        </div>
        <button mat-button (click)="doAction()" mat-flat-button color="warn"
            [disabled]="!userForm.valid">Solicitar</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </form>
</mat-dialog-content>
