<h2 class="font-medium" mat-dialog-title><strong>Gestión asesoria</strong></h2><mat-dialog-content>
<div fxLayout="row wrap">
    <div fxFlex.gt-sm="50%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <mat-card-title>
                    <h4 class="m-0">{{local_data.titulo}}</h4>
                </mat-card-title>
                <mat-card-subtitle>{{local_data.fecha | date}}</mat-card-subtitle>
                <p>
                    {{local_data.mensaje}}
                </p>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="50%" fxFlex="100">
        <mat-card>
            <mat-card-content>
                <mat-card-title>
                    <h4 class="m-0">Documentos adjuntos</h4>
                </mat-card-title>
                <mat-list>
                    <mat-list-item *ngFor="let doc of local_data.archivos">

                        <label>{{doc}}</label>
                        <button mat-icon-button style="color: green;" (click)="downloadDoc(doc)">
                            <mat-icon>cloud_download</mat-icon>
                        </button>

                    </mat-list-item>
                </mat-list>               
            </mat-card-content>
        </mat-card>
    </div>
</div>
    <form #userForm="ngForm">   
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex="100" fxFlex.gt-md="100">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="titulo" name="titulo" [(ngModel)]="respuesta.titulo" placeholder="Título">
                    </mat-form-field>
                </div>
            </div>            
            <div fxFlex="100" fxFlex.gt-md="100">
                <div class="m-r-15 m-l-15">
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Texto respuesta</mat-card-title>
                            <quill-editor name="texto" required [(ngModel)]="respuesta.mensaje"></quill-editor>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

        </div>
        <button mat-button (click)="responder()" mat-flat-button color="warn" [disabled]="!userForm.valid">Responder</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </form>
</mat-dialog-content>



