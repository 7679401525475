<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
                        <mat-form-field>
                            <input matInput (keydown.enter)="applyFilter($event.target.value)" placeholder="Buscar memoria ...">
                        </mat-form-field>
                        
                    </div>
                    <div fxFlex.gt-md="75" class="text-right">
                        <button mat-raised-button (click)="openDialog('Generar',{})" color="primary">Nueva memoria</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- 
    ['nombre', 'fecha', 'colegiados', 'docCirculares ', 'docAnuncios', 'docIpc', 'docLegislacion', 'docFormacion', 'webinars', 'action']
-->
<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

                        <ng-container matColumnDef="nombre">
                            <th mat-header-cell *matHeaderCellDef> Nombre </th>
                            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fecha">
                            <th mat-header-cell *matHeaderCellDef> Fecha creación </th>
                            <td mat-cell *matCellDef="let element"> {{element.fecha | date}} </td>
                        </ng-container>

                        

                        <ng-container matColumnDef="colegiados">
                            <th mat-header-cell *matHeaderCellDef> Colegiados </th>
                            <td mat-cell *matCellDef="let element"> {{element.colegiados}} </td>
                        </ng-container>

                        <ng-container matColumnDef="docCirculares">
                            <th mat-header-cell *matHeaderCellDef> Doc. Circulares </th>
                            <td mat-cell *matCellDef="let element"> {{element.circulares}} </td>
                        </ng-container>

                        <ng-container matColumnDef="docAnuncios">
                            <th mat-header-cell *matHeaderCellDef> Doc. Anuncios </th>
                            <td mat-cell *matCellDef="let element"> {{element.anuncios}} </td>
                        </ng-container>
                        <ng-container matColumnDef="docIpc">
                            <th mat-header-cell *matHeaderCellDef> Doc. I.P.C </th>
                            <td mat-cell *matCellDef="let element"> {{element.ipc}} </td>
                        </ng-container>
                        <ng-container matColumnDef="docLegislacion">
                            <th mat-header-cell *matHeaderCellDef> Doc. Legislación </th>
                            <td mat-cell *matCellDef="let element"> {{element.legislacion}} </td>
                        </ng-container>
                        <ng-container matColumnDef="docFormacion">
                            <th mat-header-cell *matHeaderCellDef> Doc. Formación </th>
                            <td mat-cell *matCellDef="let element"> {{element.formacion}} </td>
                        </ng-container>

                        <ng-container matColumnDef="webinars">
                            <th mat-header-cell *matHeaderCellDef> Webinars </th>
                            <td mat-cell *matCellDef="let element"> {{element.webinars}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Acciones </th>
                            <td mat-cell *matCellDef="let element" class="action-link">
                               
                            <a (click)="openDialog('Eliminar',element)" class="m-r-10 cursor-pointer">
                                   <mat-icon style="color: red">delete</mat-icon>
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #paginatoR [length]="length" [pageSize]="10" (page)="pageEvent = getServerData($event)"></mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>