import { environment } from './../../../environments/environment';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Curso } from '../../../models/curso.model';
import { DocumentSelectComponent } from '../../Selects/document-select/document-select.component';

@Component({
  selector: 'app-curso-form',
  templateUrl: './curso-form.component.html',
  styleUrls: []
})
export class CursoFormComponent implements OnInit {

  action: string = 'Nuevo';
  local_data: any;
  document: string =  '';
  documentos: any[] = [];

  constructor(private dialog: MatDialog, @Optional() @Inject(MAT_DIALOG_DATA) public data: Curso, public dialogRef: MatDialogRef<CursoFormComponent>) {
    this.local_data = { ...this.data };
    this.action = this.local_data.action;
    if (this.action == 'Nuevo'){
      this.local_data.cuerpo = `
      <p class="ql-align-center">
      <img src="https://cafsevilla.com/wp-content/uploads/2022/07/formacion-04.png" alt="cabecera"  width="640"></p>
      <p><br></p>
      <p>Hola {{nombre}}</p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p class="ql-align-center">
        <a href="{{url}}" rel="noopener noreferrer" target="_blank" style="color: rgb(128, 128, 128);">
        <img src="https://cafsevilla.com/wp-content/uploads/2022/07/boton-07.jpg" width="20% "></a>
      </p>
      <p><br></p>
      <p><br></p>
      <p class="ql-align-center">
        <img src="https://cafsevilla.com/wp-content/uploads/2022/04/faldon-2-133.png" alt="faldon-2-13" width="640"></a>
      </p>
      <p><br></p>`;
      this.local_data.respuesta = `
      <p class="ql-align-center">
      <img src="https://cafsevilla.com/wp-content/uploads/2022/07/formacion-04.png" alt="cabecera"  width="640"></p>
      <p><br></p>
      <p>Hola {{nombre}}</p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p class="ql-align-center"><span style="color: rgb(128, 128, 128);"><img src="https://cafsevilla.com/wp-content/uploads/2022/04/faldon-2-133.png" alt="faldon-2-13" width="640"></span></p>
      <p><br></p>`;
    }
    if (this.action == 'Clonar'){
      delete this.local_data._id;
    }
  }

  ngOnInit(): void {

  }

  addDoc(){
    const dialogRef = this.dialog.open(DocumentSelectComponent, {});
    dialogRef.afterClosed().subscribe( async result => {
      if (!result) return;
      this.document = this.document +  `<p><a href="${environment.backendUrl}/documentos/download/DOCUMENTO/${result.doc}" rel="noopener noreferrer" target="_blank">${result.titulo}</a></p>`
      this.local_data.cuerpo = `<p class="ql-align-center">
      <img src="https://cafsevilla.com/wp-content/uploads/2022/07/formacion-04.png" alt="cabecera"  width="640"></p>
      <p><br></p>
      <p>Hola {{nombre}}</p>
      <p><br></p>
      ${this.document}
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p class="ql-align-center">
        <a href="{{url}}" rel="noopener noreferrer" target="_blank" style="color: rgb(128, 128, 128);">
        <img src="https://cafsevilla.com/wp-content/uploads/2022/07/boton-07.jpg" width="20% "></a>
      </p>
      <p><br></p>
      <p><br></p>
      <p class="ql-align-center">
        <img src="https://cafsevilla.com/wp-content/uploads/2022/04/faldon-2-133.png" alt="faldon-2-13" width="640"></a>
      </p>
      <p><br></p>`;

    });

  }

  doAction(){
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
