import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vimeo'
})
export class VimeoPipe implements PipeTransform {

  transform(video: string): string {
    console.log(`https://player.vimeo.com/video/${video}`)
    return `https://player.vimeo.com/video/${video}`;
  }

}
