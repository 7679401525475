import { Component, Optional, Inject } from '@angular/core';
import { DocumentoService } from '../../services/documento.service';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from '../../services/file.service';

export interface Documento {
  original?: string;
  subido?: string;
}

@Component({
  selector: 'app-documento-form',
  templateUrl: './documento-form.component.html',
  styles: []
})


export class DocumentoFormComponent  {

  action: string;
  local_data: any;
  selectedImage: any = '';
  documento: Documento = {};

  constructor(
    private fileService: FileService,
    private documentoService: DocumentoService,
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<DocumentoFormComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Documento) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    this.documento.original = this.local_data.doc;
    this.documento.subido = this.local_data.doc;
  }



  doAction(): void {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog(): void {
    if (this.documento.subido){
      this.removeDoc(this.documento.subido);
    }
    this.dialogRef.close({ event: 'Cancel' });
  }

  async selectFile(event: any) {
    if (!event.target.files[0] || event.target.files[0].length === 0) {
      return;
    }
    const file: File = event.target.files[0];

    // tslint:disable-next-line - Disables all
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    // tslint:disable-next-line - Disables all
    const fileUpload = await this.fileService.addDoc('DOCUMENTO', file);

    if(fileUpload.ok){
      this.documento = {original: file.name, subido: fileUpload.nombreArchivo};
      this.local_data.doc = fileUpload.nombreArchivo;
      console.log(this.documento);
    }
  }

  async removeDoc(doc: string){
    await this.fileService.delDoc('DOCUMENTO', doc);
    this.documento = {};
    this.local_data.doc = undefined;
  }


}
