import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor( private dataService: DataService) { }

  addDoc(tipo: string, file: File){
    return new Promise<any>(resolve => {
      this.dataService.uploadDocumento(`documentos/upload/${tipo}`, file).then((resp: any) => {
        resolve(resp);
      });
    });
  }

  getDoc(tipo: string, file: string){
    return new Promise<any>(resove => {
      this.dataService.downloadDocumento(`documentos/download/${tipo}/${file}`).then(resp => {
        resove(resp);
      });
    });
  }

  delDoc(tipo: string, file: string){
    return new Promise<any>(resolve => {
      this.dataService.delDocumento(`documentos/del/${tipo}/${file}`).then(resp => {
        resolve(resp);
      });
    });
  }

}
