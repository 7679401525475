import { environment } from './../../../environments/environment';
import { Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DocumentSelectComponent } from '../../Selects/document-select/document-select.component';

@Component({
  selector: 'app-mailing-form',
  templateUrl: './mailing-form.component.html',
  styles: []
})
export class MailingFormComponent {

  action: string;
  local_data: any;
  document: string =  '';
  selectedImage: any = '';
  documentos: any[] = [];

  constructor(

    private dialog: MatDialog,
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<MailingFormComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    if (!this.local_data.cuerpo) {
      this.local_data.cuerpo = `
      <p class="ql-align-center">
      <img src="https://cafsevilla.com/wp-content/uploads/2022/04/cafs-newsletter-circular2.png" alt="cabecera"  width="640"></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p class="ql-align-center"><span style="color: rgb(128, 128, 128);"><img src="https://cafsevilla.com/wp-content/uploads/2022/04/faldon-2-133.png" alt="faldon-2-13" width="640"></span></p>
      <p><br></p>`
    }
    this.action = this.local_data.action;

  }


  doAction(): void {
    this.local_data.fecha = new Date();
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  addDoc(){
    const dialogRef = this.dialog.open(DocumentSelectComponent, {});
    dialogRef.afterClosed().subscribe( async result => {
      if (!result) return;
      this.document = this.document +  `<p><a href="${environment.backendUrl}/documentos/download/DOCUMENTO/${result.doc}" rel="noopener noreferrer" target="_blank">${result.titulo}</a></p>`
      this.local_data.cuerpo = `

      <p class="ql-align-center">
      <img src="https://cafsevilla.com/wp-content/uploads/2019/02/cafs-newsletter-circular.png" alt="cabecera" width="600"></p>
      <p><br></p>
      ${this.document}
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p class="ql-align-center"><span style="color: rgb(128, 128, 128);"><img src="https://cafsevilla.com/wp-content/uploads/2019/02/faldon-2-13.jpg" alt="faldon-2-13" width="660"></span></p>
      <p><br></p>
      <p class="ql-align-justify"><span style="color: rgb(128, 128, 128);">Este mensaje y sus adjuntos contienen información confidencial y reservada dirigida exclusivamente a su destinatario. Si ha recibido este mensaje por error, se ruega lo notifique inmediatamente por esta misma vía y borre el mensaje de su sistema. Nótese que el correo electrónico vía internet no permite asegurar ni la confidencialidad de los mensajes que se transmite ni la correcta recepción de los mismos. Los datos derivados de su correspondencia se incluyen en un fichero titularidad de COLEGIO DE ADMINISTRADORES DE FINCAS DE SEVILLA, cuya finalidad exclusiva es gestionar las comunicaciones de este colegio, entendiéndose que usted consiente el tratamiento de los mismos con dicha finalidad al mantener tal correspondencia. El ejercicio de sus derechos de acceso, rectificación, cancelación u oposición puede realizarlo dirigiéndose por escrito a la dirección c/ Carlos Cañal, 22 – 41001 SEVILLA. </span></p><p class="ql-align-center"><span style="background-color: rgb(232, 232, 232); color: rgb(0, 0, 0);">Calle Carlos Cañal, 22, 41001 Sevilla</span></p>`;

    });

  }
}
