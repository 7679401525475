import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ColegiadoService } from './colegiado.service';
import { Colegiado } from '../../models/colegiado.model';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportXlsService {

  constructor(private colegiadoService: ColegiadoService) { }


   exportColegiados(ejercientes: boolean){

    return new Promise<boolean>(async resolve => {
      const colegiados = await this.colegiadoService.getColegiadosExport(ejercientes);
      if (!colegiados.ok) return window.alert(colegiados.error);

      if (!colegiados.colegiados) return window.alert('Error, no se han localizado colegiados para exportar');

      this.exportAsExcelFile(await this.formatColegiadosData(colegiados.colegiados), 'listado_colegiados');
        resolve(true);
    });

  }

  exportAsistenciaCurso(asistencia: any[]){
    return new Promise<boolean>(async resolve => {
      this.exportAsExcelFile(await this.formatAsistenciaCurso(asistencia), 'listado_colegiados');
        resolve(true);
    });

  }

  private formatAsistenciaCurso(asistencia: any[]){
    return new Promise<any[]>(resolve => {
      const ass: any[] = [];
      let count = 0;
      asistencia.forEach(a => {
        let fechaInicio = new Date(a.curso.fechaInicio);
        let fechaFin = new Date(a.curso.fechaFin);
        const asistente = {
          CURSO: a.curso.titulo,
          FECHA_INICIO: fechaInicio,
          FECHA_FIN: fechaFin,
          COLEGIADO: a.colegiado.nombre,
          DNI_NIF: a.colegiado.dni,
          EMAIL: a.colegiado.email,
          COMPLETA_FORMACION: a.asistencia?'SI': 'NO'
        }
        ass.push(asistente);
        count ++;
        if (count == asistencia.length){
          console.log(ass);
          resolve(ass);
        }
      });
    })
  }

  private formatColegiadosData(colegiados: Colegiado[]){
    return new Promise<any[]>(resolve => {
      const coleg: any[] = [];
      let count = 0;
      colegiados.forEach(c => {
        let fechaNacimiento;
        let fechaAlta;
        let fechaBaja;
        if (c.fechaNacimiento) fechaNacimiento = new Date(c.fechaNacimiento);
        if (c.fechaAlta) fechaAlta = new Date(c.fechaAlta);
        if (c.fechaBaja) fechaBaja = new Date(c.fechaBaja);
        const colegiado = {
          NOMBRE: c.nombre,
          NIF: c.dni,
          FECHA_NACIMIENTO: fechaNacimiento || '',
          FECHA_ALTA: fechaAlta || '',
          FECHA_BAJA: fechaBaja || '',
          EMAIL: c.email,
          TELÉFONO_FIJO: c.tlfFijo || '',
          TELÉFONO_MOVIL: c.tlfMovil || '',
          N_TERRITORIAL: c.nTerritorial || '',
          N_NACIONAL: c.nNacional || '',
          PROVINCIA: c.provinciaProf || 'SEVILLA',
          POBLACIÓN: c.poblacionProf || '',
          DIRECCIÓN: c.direccionProf || '',
          CP: c.cpProf || '',
          ESTUDIOS: c.tituloUni || '',
          EJERIENTE: c.ejerciente? 'SI': 'NO',
          BAJA: c.estado? 'NO': 'SI'
        }
        coleg.push(colegiado);
        count ++;
        if (count == colegiados.length){
          resolve(coleg);
        }
      });
    });
  }

  private exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
