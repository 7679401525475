import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Documento } from 'src/models/documento.model';

import { DocumentoService } from '../../services/documento.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-select',
  templateUrl: './document-select.component.html',
  styleUrls: ['./document-select.component.scss']
})
export class DocumentSelectComponent implements OnInit {

  documents: Documento[] = [];
  document: Documento = {};
  type: string = '';
  constructor(private documentoService: DocumentoService, public dialogRef: MatDialogRef<DocumentSelectComponent>,) { }

  ngOnInit(): void {
  }

  async loadDataDocument(search?: string){
    this.documentoService.page = 0;
    if (this.type != '' ){
      const dsResp = await this.documentoService.getDocumnetos(this.type, search);
      if (!dsResp.ok){
        return window.alert(dsResp.error);
      }
      this.documents = dsResp.documentos || [];
    }
  }

  doAction(){
    this.dialogRef.close(this.document);
  }

  onSelectField(type: string){
    this.type = type;
    this.loadDataDocument();
  }

  applyFilter(filterValue: string) {
    if (this.type != '') this.loadDataDocument(filterValue);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
