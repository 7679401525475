import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UiService } from '../../services/ui.service';
import { CursosService } from '../../services/cursos.service';
import { element } from 'protractor';

@Component({
  selector: 'app-a-cursos',
  templateUrl: './a-cursos.component.html',
  styleUrls: []
})
export class ACursosComponent implements OnInit {

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  pages: any [] = [0];
  pageEvent: any = {};
  displayedColumns: string[] = [
    'nombre',
    'fechaInicio',
    'fechaFin',
    'modalidad',
    'action'
  ];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);

  length: number = 0;

  constructor(private ui: UiService, private cursoService: CursosService) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(){
    const resp = await this.cursoService.getAsistenciaColegiado();
    if (!resp.ok){
      return window.alert(resp.error);
    }
    this.dataSource = resp.cursos || [];
  }

  async certificadoAsistencia(element: any){
    const resp = await this.cursoService.getCertificadoCurso(element.curso._id);
    if (!resp.ok){
      return window.alert(resp.error);
    }
    this.ui.presentInfo('Solicitud enviada al colegio', 'OK');
  }

  applyFilter(event: string){
    this.dataSource.filter = event.trim().toLowerCase();
  }

}
