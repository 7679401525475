<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <label for="" style="color: #8E1537"> LISTADO DE CURSOS DEL COLEGIADO {{colegiado.nombre?.toUpperCase()}} </label>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

                        <ng-container matColumnDef="nombre">
                            <th mat-header-cell *matHeaderCellDef> Nombre </th>
                            <td mat-cell *matCellDef="let element"> {{element.curso.titulo }} </td>
                        </ng-container>

                        <ng-container matColumnDef="fechaInicio">
                            <th mat-header-cell *matHeaderCellDef> Fecha Inicio </th>
                            <td mat-cell *matCellDef="let element"> {{element.curso.fechaInicio | date}} </td>
                        </ng-container>

                        <ng-container matColumnDef="fechaFin">
                            <th mat-header-cell *matHeaderCellDef> fecha Fin </th>
                            <td mat-cell *matCellDef="let element"> {{element.curso.fechaFin | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="modalidad">
                            <th mat-header-cell *matHeaderCellDef> Modalidad </th>
                            <td mat-cell *matCellDef="let element"> {{element.curso.tipo}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #paginatoR [length]="length" pageSize="10" (page)="pageEvent = $event"></mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>