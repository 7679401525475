<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
                        <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar colegiado ...">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-md="75" class="text-right">
                        <button mat-raised-button (click)="openDialog('Nuevo',{})" color="primary">Nueva Solicitud</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

                        <ng-container matColumnDef="dni">
                            <th mat-header-cell *matHeaderCellDef> DNI </th>
                            <td mat-cell *matCellDef="let element"> {{element.dni}} </td>
                        </ng-container>

                        <ng-container matColumnDef="nombre">
                            <th mat-header-cell *matHeaderCellDef> Nombre </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="d-flex">
                                    <img class="mat-card-avatar" [src]="element.avatar || '../../assets/images/users/default.png'">
                                    <div class="m-l-15">
                                        <p class="font-medium m-b-0 m-t-0">{{ element.nombre }}</p>
                                        <small class="text-muted">{{element.tituloUni}}</small>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Email </th>
                            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="tlfMovil">
                            <th mat-header-cell *matHeaderCellDef> Móvil </th>
                            <td mat-cell *matCellDef="let element"> {{element.tlfMovil}} </td>
                        </ng-container>

                        <ng-container matColumnDef="fechaSolicitud">
                            <th mat-header-cell *matHeaderCellDef> Fecha baja </th>
                            <td mat-cell *matCellDef="let element"> {{element.fechaBaja | date }} </td>
                        </ng-container>

                        <ng-container matColumnDef="poblacion">
                            <th mat-header-cell *matHeaderCellDef> Población </th>
                            <td mat-cell *matCellDef="let element"> {{element.poblacionProf}} </td>
                        </ng-container>

                        <ng-container matColumnDef="nTerritorial">
                            <th mat-header-cell *matHeaderCellDef> N. Territorial </th>
                            <td mat-cell *matCellDef="let element"> {{element.nTerritorial}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Acciones </th>
                            <td mat-cell *matCellDef="let element" class="action-link">
                                <a (click)="openDialog('Actualiza',element)" class="m-r-10 cursor-pointer">
                                    <mat-icon style="color: #8E1537">edit</mat-icon>
                                </a>
                            <a (click)="openDialog('Eliminar',element)" class="m-r-10 cursor-pointer">
                                   <mat-icon style="color: red" >delete</mat-icon>
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>