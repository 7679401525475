<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" style="text-align: center;">
                    <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="50">
                        <img src="../../../../assets/images/cabecera-guia.jpg" style="height: 100%; width: 100%;">
                    </div>
                    <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="50">
                        <div fxLayout="row wrap" style="text-align: center;">
                            <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 5em;">
                                <button mat-raised-button color="primary">
                                    <a style="color: white;" download="guia.pdf" target="_blank" href="../../../../assets/doc/guia.pdf">
                                        DESCARGAR GUÍA EN PDF
                                    </a>
                                </button>
                                
                            </div>
                            <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 5em;">
                                <button mat-raised-button color="primary">
                                    <a style="color: white;" download="b1.xlsx" target="_blank" href="../../../../assets/doc/b1.xlsx">
                                        BLOQUE 1: EDIFICACIÓN
                                    </a>
                                </button>
                            </div>
                            <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 5em;">
                                <button mat-raised-button color="primary">
                                    <a style="color: white;" download="b2.xlsx" target="_blank" href="../../../../assets/doc/b2.xlsx">
                                        BLOQUE 2: INSTALACIONES
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>                                        
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                        <label>
                            Desde el <b style="color: #8E1537;">Colegio Territorial de Administradores de Fincas de Sevilla</b> pretendemos que esta GUÍA facilite el correcto uso y el adecuado mantenimiento de su edificio. El objeto de este documento es de servir de guía a las comunidades de propietarios, para seguir fácilmente el control y planificación de la conservación y mantenimiento de sus comunidades.                          
                        </label>    
                    </div>
                    <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 2em;">
                        <label>
                            Te recordamos que según Ley de Ordenación de la Edificación (LOE) Ley 38/1999 de 5 de noviembre (BOE nº 266 de 6 de noviembre de 1999, en el artículo 16, hace mención a la obligatoriedad de los propietarios y usuarios.
                        </label>    
                    </div>
                    <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 2em;">
                        <label>
                            Para el correcto seguimiento del calendario periódico de mantenimiento. Le facilitamos las <b style="color: #8E1537;">FICHAS</b>, para la llevanza en cada edificio que gestione. No obstante, le indicamos de la conveniencia de comprobar en el Libro de Edificio la tabla de periodicidad aconsejada para el buen uso, inspecciones y mantenimiento a acometer. En este supuesto deberá establecer a razón de su calendario, las adaptaciones oportunas en las fichas.
                        </label>    
                    </div>
                    <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 2em;">
                        <label>
                            Esperamos que los consejos e instrucciones que ofrecemos en esta <b style="color: #8E1537;">Guía de Mantenimiento</b> se tomen como una herramienta útil que contribuya a mejorar la salud de nuestros inmuebles.
                        </label>    
                    </div>
                       
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
