import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UiService } from '../../../services/ui.service';
import { CursosService } from '../../../services/cursos.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import globalUser from '../../../../environments/globales';
import { ExportXlsService } from '../../../services/export-xls.service';
import { Asistencia } from '../../../../models/curso.model';

@Component({
  selector: 'app-asistentes',
  templateUrl: './asistentes.component.html',
  styleUrls: []
})
export class AsistentesComponent implements OnInit {

  asistentes: any[] = [];
  curso: string = 'Curso Test';
  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  pageEvent: any;
  pages: any[] = [0];
  displayedColumns: string[] = [
    'dni',
    'nombre',
    'nterritorial',
    'asistencia',
    'accion'
  ];
  dataSource: MatTableDataSource<Asistencia>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  length: number = 0;
  permiso = globalUser.permiso;
  constructor(private ui: UiService, private cursoService: CursosService, private xlsService: ExportXlsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AsistentesComponent>) {
      this.dataSource = new MatTableDataSource();
    }

  ngOnInit(): void {
    this.curso = this.data.titulo;
    this.loadData();
    this.length = this.dataSource.data.length;
    this.paginator.length = this.length;
    this.dataSource.paginator = this.paginator;
  }

  async loadData(){
    const resp = await this.cursoService.getAsitencia(this.data._id);
    if (!resp.ok){
      return window.alert(resp.error);
    }
    this.dataSource.data = resp.asistentes || [];

  }

  async applyFilter(filterValue: string) {
    const resp = await this.cursoService.getAsitencia(this.data._id, filterValue);
    if (!resp.ok){
      return window.alert(resp.error);
    }
    console.log('Filter');
    this.dataSource.data = resp.asistentes || [];
  }

  async asiste(asistencia: any){
    const resp: any = await this.cursoService.setAsistencia(asistencia);
    if(!resp.ok){
      return window.alert(resp.error);
    }
    this.ui.presentInfo('Colegido con asistencia marcada en el curso', 'OK');
    this.loadData();
  }

 async exportXls(){
  const asistencia = await this.cursoService.getAsitencia(this.data._id);
  if (!asistencia.ok){
    return window.alert(asistencia.error);
  }
    await this.xlsService.exportAsistenciaCurso(this.dataSource.data);
  }


}
