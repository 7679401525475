import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { WebinarResp, Webinar } from '../../models/webinar.model';

@Injectable({
  providedIn: 'root'
})
export class WebinarService {

  constructor( private dataService: DataService) { }

  getWebinars(){
    return new Promise<WebinarResp>(resolve => {
      this.dataService.getData('webinars').then((resp: WebinarResp) => {
        resolve(resp);
      });
    });
  }

  serWebinar(webinar: Webinar){
    return new Promise<WebinarResp>(resolve => {
      this.dataService.setData('webinars', webinar).then((resp: WebinarResp) => {
        resolve(resp);
      });
    }); 
  }

  updateWebinar(webinar: Webinar){
    return new Promise<WebinarResp>(resolve => {
      this.dataService.updateData(`webinars/${webinar._id}`, webinar).then((resp: WebinarResp) => {
        resolve(resp);
      });
    });
  }

  delWebinar(webinar: Webinar){
    return new Promise<WebinarResp>(resolve => {
      this.dataService.delData(`webinars/${webinar._id}`).then((resp: WebinarResp) =>{
        resolve(resp);
      });
    });
  }

}
