import { UsuarioFormComponent } from './formularios/usuario-form/usuario-form.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { VerticalAppHeaderComponent } from './layouts/full/vertical-header/vertical-header.component';
import { VerticalAppSidebarComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import { HorizontalAppHeaderComponent } from './layouts/full/horizontal-header/horizontal-header.component';
import { HorizontalAppSidebarComponent } from './layouts/full/horizontal-sidebar/horizontal-sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthenticationModule } from './authentication/authentication.module';
import { LoginComponent } from './login/login.component';
import { LoginService } from './services/login.service';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { DatePipe } from '@angular/common';
import { ColegiadoFormComponent } from './formularios/colegiado-form/colegiado-form.component';
import { ColegiadosComponent } from './pages/colegio/colegiados/colegiados.component';
import { SolicitudesComponent } from './pages/colegio/solicitudes/solicitudes.component';
import { DemoMaterialModule } from './demo-material-module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { LopdComponent } from './pages/asesoria/lopd/lopd.component';
import { JuridicaComponent } from './pages/asesoria/juridica/juridica.component';
import { LaboralComponent } from './pages/asesoria/laboral/laboral.component';
import { FiscalComponent } from './pages/asesoria/fiscal/fiscal.component';
import { TecnicaComponent } from './pages/asesoria/tecnica/tecnica.component';
import { AsesoriaFormComponent } from './formularios/asesoria-form/asesoria-form.component';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { DocumentoPipe } from './pipes/documento.pipe';
import { BurofaxComponent } from './pages/utilidades/burofax/burofax.component';
import { MantenimientoComponent } from './pages/utilidades/mantenimiento/mantenimiento.component';
import { IcredComponent } from './pages/utilidades/icred/icred.component';
import { WebinarComponent } from './pages/utilidades/webinar/webinar.component';
import { WebinarFormComponent } from './formularios/webinar-form/webinar-form.component';
import { QuillModule } from 'ngx-quill';
import { WebinarVisorComponent } from './visores/webinar-visor/webinar-visor.component';
import { VimeoPipe } from './pipes/vimeo.pipe';
import { CircularesComponent } from './pages/documentacion/circulares/circulares.component';
import { LegislacionComponent } from './pages/documentacion/legislacion/legislacion.component';
import { IpcComponent } from './pages/documentacion/ipc/ipc.component';
import { DocumentoFormComponent } from './formularios/documento-form/documento-form.component';
import { FormacionComponent } from './pages/documentacion/formacion/formacion.component';
import { TablonComponent } from './pages/documentacion/tablon/tablon.component';
import { AsesoriaComponent } from './visores/asesoria/asesoria.component';
import { RespuestaAsesoriaComponent } from './visores/respuesta-asesoria/respuesta-asesoria.component';
import { CorreoComponent } from './pages/ajustes/correo/correo.component';
import { CuotasComponent } from './pages/ajustes/cuotas/cuotas.component';
import { ComunicacionesComponent } from './pages/comunicaciones/comunicaciones.component';
import { MailingFormComponent } from './formularios/mailing-form/mailing-form.component';
import { MemoriasComponent } from './pages/colegio/memorias/memorias.component';
import { MemoriaFormComponent } from './formularios/memoria-form/memoria-form.component';
import { SolicitarUsuarioComponent } from './formularios/solicitar-usuario/solicitar-usuario.component';
import { DateSearchComponent } from './formularios/date-search/date-search.component';
import { DocumentSelectComponent } from './Selects/document-select/document-select.component';
import { CursosComponent } from './pages/cursos/cursos.component';
import { CursoFormComponent } from './formularios/curso-form/curso-form.component';
import { AsistentesComponent } from './pages/cursos/asistentes/asistentes.component';
import { ACursosComponent } from './pages/a-cursos/a-cursos.component';
import { ReportesComponent } from './pages/cursos/reportes/reportes.component';
import { ColegiadoPasswdComponent } from './formularios/colegiado-passwd/colegiado-passwd.component';





// tslint:disable-next-line: typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    VerticalAppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    VerticalAppSidebarComponent,
    AppBreadcrumbComponent,
    HorizontalAppHeaderComponent,
    HorizontalAppSidebarComponent,
    LopdComponent,
    JuridicaComponent,
    LaboralComponent,
    FiscalComponent,
    TecnicaComponent,
    CorreoComponent,
    CuotasComponent,
    LoginComponent,
    LoginAdminComponent,
    UsuariosComponent,
    ColegiadosComponent,
    ColegiadoFormComponent,
    SolicitarUsuarioComponent,
    UsuarioFormComponent,
    AsesoriaFormComponent,
    SolicitudesComponent,
    BurofaxComponent,
    MantenimientoComponent,
    IcredComponent,
    WebinarComponent,
    WebinarFormComponent,
    ComunicacionesComponent,
    MailingFormComponent,
    CursoFormComponent,
    WebinarVisorComponent,
    AsesoriaComponent,
    CircularesComponent,
    FormacionComponent,
    LegislacionComponent,
    TablonComponent,
    IpcComponent,
    RespuestaAsesoriaComponent,
    MemoriasComponent,
    MemoriaFormComponent,
    DocumentoFormComponent,
    DateSearchComponent,
    DocumentoPipe,
    VimeoPipe,
    ComunicacionesComponent,
    DocumentSelectComponent,
    CursosComponent,
    AsistentesComponent,
    ACursosComponent,
    ReportesComponent,
    ColegiadoPasswdComponent
  ],
  imports: [
    AuthenticationModule,
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    MatTableModule,
    MatSortModule,
    QuillModule.forRoot(),
    RouterModule.forRoot(AppRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    LoginService,
    DatePipe,
    VimeoPipe

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
