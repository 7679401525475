import { Component, Optional, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import {FormControl} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
import { Moment } from 'moment';


const moment = _moment;


export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LLLL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-memoria-form',
  templateUrl: './memoria-form.component.html',
  styles: ['']
})
export class MemoriaFormComponent {

  date = new FormControl(moment());
  action: string;
  local_data: any;
  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<MemoriaFormComponent>,
        // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.local_data = { ...data };
      this.action = this.local_data.action;
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }


  doAction(): void {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
