<h2 class="font-medium" mat-dialog-title>
    <strong>Seleccione un documento</strong></h2>
<form #documentForm="ngForm">

    <div fxLayout="row wrap" class="align-items-center">

        <div fxFlex="30" fxFlex.gt-md="30">
            <mat-form-field>
                <mat-select placeholder="Tipo de documento" [(ngModel)]="type" (cdkObserveContent)="onSelectField(type)" name="type">
                    <mat-option value="CIRCULAR">Circular</mat-option>
                    <mat-option value="ANUNCIO">Tablón de anuncios</mat-option>
                    <mat-option value="IPC">IPC</mat-option>
                    <mat-option value="LESGISLA">Legislación</mat-option>
                    <mat-option value="FORMACION">Formación</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="10" fxFlex.gt-md="10"></div>
        <div fxFlex="60" fxFlex.gt-md="60">
            <mat-form-field>
                <input matInput placeholder="Documento" (keyup)="applyFilter($event.target.value)" [matAutocomplete]="doc" class="font-14">
                <mat-autocomplete #doc="matAutocomplete">
                    <mat-option *ngFor="let item of documents" [value]="item.titulo" (click)="document = item">
                        <span class="font-14">{{item.titulo}}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-md="100">
            <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!documentForm.valid">Seleccionar</button>
            <button mat-button (click)="closeDialog()">Cancel</button>
        </div>
    </div>


</form>

<!--
      <div fxFlex.gt-sm="31.3" fxFlex="31.3" style="margin-left: 2px;">

        </div>
     -->