import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { MailingResp, Mailing } from '../../models/mailing.model';

@Injectable({
  providedIn: 'root'
})
export class MailingService {

  constructor(private dataService: DataService) { }

  page = 0;

  getMailings(buscar?: string){
    this.page ++;
    return new Promise<MailingResp>(resolve => {
      this.dataService.getData(`mailing?page=${this.page}&buscar=${buscar}`).then((resp: MailingResp) => {
        resolve(resp);
      });
    });
  }

  setMailing(mailing: Mailing){
    return new Promise<MailingResp>(resolve => {
      this.dataService.setData('mailing', mailing).then((resp: MailingResp) => {
        resolve(resp);
      });
    });
  }

  updateMailing(mailing: Mailing){
    return new Promise<MailingResp>(resolve => {
      this.dataService.updateData(`mailing/${mailing._id}`, mailing).then((resp: MailingResp) => {
        resolve(resp);
      });
    });
  }

  testMailing(mailingId: string){
    return new Promise<any>(resolve => {
      this.dataService.updateData(`mailing/test/${mailingId}`, {}).then((resp: MailingResp) => {
        resolve(resp);
      });
    });
  }

  delMailing(mailingId: string){
    return new Promise<MailingResp>(resolve => {
      this.dataService.delData(`mailing/${mailingId}`).then((resp: MailingResp) => {
        resolve(resp);
      });
    });
  }

  sendaMailing(mailingId: string){
    return new Promise<MailingResp>(resolve => {
      this.dataService.updateData(`mailing/send/${mailingId}`, {}).then((resp: MailingResp) => {
        resolve(resp);
      });
    });
  }
}
