<div fxLayout="row wrap">
    <div fxFlex.gt-sm="33.33%" fxFlex="100">
        <mat-card>
            <mat-card-header>                
                <mat-card-title>Servidor de correo</mat-card-title>
                <mat-card-subtitle>Ajustes del servidor de correo</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form #userForm="ngForm">   
                    <div fxLayout="row wrap" class="align-items-center">
                        <div fxFlex="100" fxFlex.gt-md="100">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="text" matInput required id="serv" name="serv" [(ngModel)]="ajusteCorreo.servidorCorreo" placeholder="Servidor de correo">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="60" fxFlex.gt-md="60">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="text" matInput required id="user" name="user" [(ngModel)]="ajusteCorreo.usuarioCorreo" placeholder="Usuario servidor">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="40" fxFlex.gt-md="40">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="password" matInput required id="user" name="user" [(ngModel)]="ajusteCorreo.passCorreo" placeholder="Contaseña">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.gt-md="100">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="nombreParaMostrar" matInput required id="nombreParaMostrar" name="nombreParaMostrar" [(ngModel)]="ajusteCorreo.nombreParaMostrar" placeholder="Nombre para mostrar">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="25" fxFlex.gt-md="25">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="number" matInput required id="port" name="post" [(ngModel)]="ajusteCorreo.puerto" placeholder="Puerto">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="25" fxFlex.gt-md="25">
                            <div class="m-r-15 m-l-15">
                                <section>
                                    <mat-slide-toggle name="tls" [(ngModel)]="ajusteCorreo.tls">TLS</mat-slide-toggle>
                                </section>
                            </div>
                        </div>
                        <div fxFlex="50" fxFlex.gt-md="50">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="number" matInput required id="cph" name="cph" [(ngModel)]="ajusteCorreo.correosPorHora" placeholder="Correos minuto">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
                <mat-card-actions>
                    <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!userForm.valid">Guardar</button>
                </mat-card-actions>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- Card column -->
    <div fxFlex.gt-sm="33.33%" fxFlex="100">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Datos de correo</mat-card-title>
                <mat-card-subtitle>Direcciones de correo receptora de los servicios</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form #formMail="ngForm">   
                    <div fxLayout="row wrap" class="align-items-center">
                        <div fxFlex="100" fxFlex.gt-md="100">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="text" matInput required id="admin" name="admin" [(ngModel)]="ajusteCorreo.correoAdmin" placeholder="Dirección de correo Adminstrador">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.gt-md="100">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="text" matInput required id="correoLOPD" name="correoLOPD" [(ngModel)]="ajusteCorreo.correoLOPD" placeholder="Asesoría LOPD">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.gt-md="100">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="text" matInput required id="correoFiscal" name="correoFiscal" [(ngModel)]="ajusteCorreo.correoFiscal" placeholder="Asesoría fiscal">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.gt-md="100">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="text" matInput required id="correoLboral" name="correoLboral" [(ngModel)]="ajusteCorreo.correoLboral" placeholder="Asesoría laboral">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.gt-md="100">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="text" matInput required id="correoJuridica" name="correoJuridica" [(ngModel)]="ajusteCorreo.correoJuridica" placeholder="Asesoría jurídica">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.gt-md="100">
                            <div class="m-r-15 m-l-15">
                                <mat-form-field>
                                    <input type="text" matInput required id="correoTecnica" name="correoTecnica" [(ngModel)]="ajusteCorreo.correoTecnica" placeholder="Asesoría técnica">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <br>
                </form>
                <mat-card-actions>
                    <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!formMail.valid">Guardar</button>
                </mat-card-actions>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- Card column -->
    <div fxFlex.gt-sm="33.33%" fxFlex="100">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Información</mat-card-title>
                <mat-card-subtitle>Información de interés sobre el servicio de correo</mat-card-subtitle>
            </mat-card-header>
            <div style="text-align: center;">
                <img mat-card-image src="assets/images/logoCompleto.png" style="height: 50%; width: 50%;">
            </div>
            <mat-card-content>
                <p>
                    Información relativa a los correo que de recibirán en cada una de las direcciones de correo configuradas.
                </p>
                <p>
                    <a>
                        Correo Administrador: <br>
                        Este correo recibirá las notificaciones de las nuevas soicitudes de colegiado, así como notificaciones relativas a las solicitudes de asesoria realizada en cada uno de los apartados.
                        <br>
                        <br>
                        Correo LOPD, Fical, Laboral, Jurídica, técnica: <br>
                        Estos correos solo recepcionarán las peticiones realizadas por los colegiados, sobre la asesoría relacionadas, siendo necesario contestar al mismo por medio de la aplicación
                        <br>
                        <br>
                        <br>
                    </a>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<br><br><br>
<footer>
    <div style="text-align: center;">
        <a style="font-size: 10px;">
            <strong>Extranet - INTRANET CAFS</strong> | Colegio de Administradores de Fincas de Sevilla
            <br>
            <strong>CAFS SEVILLA</strong> | Colegio Administradores de Fincas de Sevilla
            <br>
            Copyright 2021 © - Derechos de autor reservados - Está prohibida la copia, reproducción, republicación, total o parcial, del Web ni cualquiera de sus contenidos o tratamiento informático sin autorizacíon previa por su autor.
        </a>
    </div>
</footer>
