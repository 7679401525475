import { Component, OnInit, ViewChild } from '@angular/core';
import globalUser from '../../../../environments/globales';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { WebinarFormComponent } from '../../../formularios/webinar-form/webinar-form.component';
import { WebinarService } from '../../../services/webinar.service';
import { Webinar } from '../../../../models/webinar.model';
import { WebinarVisorComponent } from '../../../visores/webinar-visor/webinar-visor.component';

const WEBINARS: Webinar[] = [];

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: []
})
export class WebinarComponent implements OnInit {

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<Webinar>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  ejercientes: boolean = false;
  length: number = 0;
  pageEvent: any = {};

  permiso = globalUser.permiso;

  constructor(private dialog: MatDialog,
              private webvinarService: WebinarService) {
    if (this.permiso !== 'COLEG'){
      this.displayedColumns = ['webinar']
    } else {
      this.displayedColumns = ['webinar']
    }
    this.dataSource = new MatTableDataSource((WEBINARS));
  }


  ngOnInit(): void {
    this.loadData();
  }

  async loadData(){
    const web = await this.webvinarService.getWebinars();
    if (!web.ok){
      window.alert(web.error);
      return;
    } else if (web.webinars){
      this.length = web.webinars.length;
      WEBINARS.unshift(... web.webinars);
      this.dataSource.data = web.webinars;
    }
    this.dataSource.paginator = this.paginator;
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(WebinarFormComponent, {
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Nuevo') {
        this.addData(result.data);
      } else if (result.event === 'Actualiza') {
        this.updateRowData(result.data);
      } else if (result.event === 'Eliminar') {
        // this.deleteRowData(result.data);
      } else {

      }
    });
  }

  async addData(data: any){
    const webinar: Webinar = {
      nombre: data.nombre,
      fechaPubli: data.fechaPubli || new Date(),
      passVimeo: data.passVimeo,
      texto: data.texto,
      video: data.video
    }
    const save = await this.webvinarService.serWebinar(webinar);
    if (save.ok){
      this.loadData();
    }
  }

  async updateRowData(data: any){
    const webinar = await this.webvinarService.updateWebinar(data);
    if (webinar.ok){
      this.loadData();
      return true;
    }


  }

  openElement(webinar: Webinar){
    const dialogRef = this.dialog.open(WebinarVisorComponent, {
      data: webinar
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
