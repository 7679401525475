<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" style="text-align: center;">
                    <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="50">
                        <img src="../../../../assets/images/fondo-ICIRED.jpg" style="height: 100%; width: 100%;">
                    </div>
                    <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="50">
                        <div fxLayout="row wrap" style="text-align: center;">
                            <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 3em;">
                                <button mat-raised-button color="primary" (click)="onClick('plataforma')">ACCESO ICRED</button>
                            </div>
                            <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 3em;">
                                <button mat-raised-button color="primary">
                                    <a style="color: white;" download="icred.pdf" target="_blank" href="../../../../assets/doc/icred.pdf">
                                        PRESENTACIÓN ICRED
                                    </a>
                                </button>
                            </div>
                            <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 3em;">
                                <button mat-raised-button color="primary" (click)="onClick('acceso')" >INSTRUCCIONES DE ACCESO</button>
                            </div>
                        </div>
                    </div>                                        
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                        <label>
                            Os presentamos la <b>nueva plataforma</b> que se pone a disposición de los colegiados, donde se podrá <b>reclamar, publicar y consultar información sobre impagos</b> y a la cual también acceden las entidades bancarias, financieras, empresas o particular que tenga un interés legítimo y cumpla la normativa en vigor.
                        </label>    
                    </div>
                    <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100" style="margin-top: 2em;">
                        <label>
                            Esta plataforma de <b>ficheros de morosos</b> no restringidos de España, tiene la conformidad al acuerdo firmado entre el Consejo Andaluz y dicha plataforma para los Administradores de Fincas colegiados de Andalucía.
                        </label>    
                    </div>                    
                       
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
