import { Component, Optional, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Asesoria } from 'src/models/asesoria.model';
import * as fileSaver from 'file-saver';
import { AsesoriaService } from '../../services/asesoria.service';
import { FileService } from '../../services/file.service';


export interface Documento {
  original: string;
  subido: string;
}

@Component({
  selector: 'app-asesoria-form',
  templateUrl: './asesoria-form.component.html',
  styles: []
})


export class AsesoriaFormComponent  {

  action: string;
  tipo: string;
  local_data: any;
  selectedImage: any = '';
  documentos: Documento[] = [];
  
  constructor(
    private asesoriaService: AsesoriaService,
    public datePipe: DatePipe,
    private fileService: FileService,
    public dialogRef: MatDialogRef<AsesoriaFormComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Asesoria) {
    this.local_data = { ...data };
    console.log(this.local_data);
    this.action = this.local_data.action;
    this.tipo = this.local_data.tipo;
    if (this.local_data.archivos){
      this.local_data.archivos.forEach((d: any) => {
        this.documentos.push({original: d, subido: d});
      });
    } else {
      this.local_data.archivos = [];
    }
    /* if (this.local_data.fechaNacimiento !== undefined) {
      this.local_data.fechaNacimiento = this.datePipe.transform(new Date(this.local_data.fechaNacimiento), 'yyyy-MM-dd');
    }
    if (this.local_data.ccc){
      this.local_data.iban = this.local_data.ccc.iban || '';
      this.local_data.nc = this.local_data.ccc.nc || '';
    }

    if (this.local_data.fechaSolicitud !== undefined) {
      this.local_data.fechaSolicitud = this.datePipe.transform(new Date(this.local_data.fechaSolicitud), 'yyyy-MM-dd');
    }
    if (this.local_data.imagePath === undefined) {
      this.local_data.imagePath = 'assets/images/users/default.png';
    }*/
  }


  doAction(): void {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async selectFile(event: any) {
    if (!event.target.files[0] || event.target.files[0].length === 0) {
      return;
    }
    const file: File = event.target.files[0];
     
    // tslint:disable-next-line - Disables all
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    // tslint:disable-next-line - Disables all
    const fileUpload = await this.fileService.addDoc(this.tipo, file);
    if(fileUpload.ok){
      this.documentos.unshift({original: file.name, subido: fileUpload.nombreArchivo});
      this.local_data.archivos.unshift(fileUpload.nombreArchivo);
    } 
  }

  async downloadDoc(doc: string){
    this.fileService.getDoc('LOPD', doc).then(r => {
      let blob:any = new Blob([r], { type: 'text/json; charset=utf-8' });
			const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, doc);
    });
  }

  async removeDoc(doc: string){
    await this.fileService.delDoc(this.tipo, doc);
    this.documentos = this.documentos.filter((f: any) => {
      return f.subido != doc
    });
    this.local_data.archivos = this.local_data.archivos.filter((a: any) => {
      return a != doc;
    });

    const update = await this.asesoriaService.updateAsesoria(this.local_data);
    console.log(update);
  }


}
