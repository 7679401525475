import { Component, OnInit, ViewChild } from '@angular/core';
import { CursosService } from '../../services/cursos.service';
import { UiService } from '../../services/ui.service';
import { Curso } from '../../../models/curso.model';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import globalUser from '../../../environments/globales';
import { MatDialog } from '@angular/material/dialog';
import { CursoFormComponent } from '../../formularios/curso-form/curso-form.component';
import { AsistentesComponent } from './asistentes/asistentes.component';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: []
})
export class CursosComponent implements OnInit {

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  pageEvent: any;
  pages: any[] = [0];
  displayedColumns: string[] = [
    'titulo',
    'fechaInicio',
    'fechaFin',
    'tipo',
    'asistentes',
    'proceso',
    'enviado',
    'enviados',
    'action'
  ];
  dataSource: MatTableDataSource<Curso>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  length: number = 0;
  permiso = globalUser.permiso;

  constructor(
    private cursoService: CursosService,
    private ui: UiService,
    private dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.paginator.pageSize = 10;
    this.loadData();
    this.dataSource.paginator = this.paginator;
  }

  async loadData() {
    const resp = await this.cursoService.getCursos();
    if (!resp.ok) {
      window.alert(resp.error);
    }
    this.dataSource.data = resp.cursos || [];
  }
  /**

   */
  openDialog(action: string, obj: any): void {
    let dialogRef: any;
    if (action == 'Enviar') {
      this.envioMailing(obj._id);
    } else if (action == 'Test') {
      this.testMailing(obj._id);
    } else if (action == 'close'){
      obj.closed = true;
      this.addData(obj);
    } else if (action == 'Clonar'){
      obj.action = action;
      delete obj.id;
      dialogRef = this.dialog.open(CursoFormComponent, {
        height: '100%',
        width: '90%',
        data: obj
      });
    } else if (action == 'Eliminar') {
      obj.action = action;
      dialogRef = this.dialog.open(CursoFormComponent, {
        data: obj
      });
    }
    else {
      obj.action = action;
      dialogRef = this.dialog.open(CursoFormComponent, {
        height: '100%',
        width: '90%',
        data: obj
      });

    }
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.event === 'Nuevo') {
        this.addData(result.data);
      } else if (result.event === 'Actualiza') {
        this.addData(result.data);
      } else if (result.event === 'Clonar') {
        this.addData(result.data);
      } else if (result.event === 'Eliminar') {
        this.deleteRowData(result.data);
      }
    });

  }

async envioMailing(mailingId: string) {
    window.alert('Proceso de envio iniciado');
    const send = await this.cursoService.sendCurso(mailingId);
    this.loadData();
    if (send.error) {
      window.alert(send.error);
    }
    return;
  }

  async testMailing(obj: any) {
    const resp: any = await this.cursoService.sendTest(obj);
    if (!resp.ok){
      return window.alert(resp.error);
    }
    this.ui.presentInfo('Correo de curos test enviado', 'OK');
  }

  async addData(obj: any) {
    const resp = await this.cursoService.setOrUpdateCurso(obj);
    if (!resp.ok){
      return window.alert(resp.error);
    }
    this.ui.presentInfo('Curso creado / actualizado', 'OK');
    if (resp.curso) this.loadData();
  }

  async deleteRowData(obj: any) {
    const resp = await this.cursoService.deleteCurso(obj._id);
    if (!resp.ok) return window.alert(resp.error);
    this.ui.presentInfo(resp.mensaje || 'Curso eliminado', '');
    this.loadData();
  }


  async applyFilter(filterValue: string) {
    this.searchText = filterValue;
    const resp = await this.cursoService.getCursos(this.searchText);
    if (!resp.ok) {
      window.alert(resp.error);
      return;
    }
      this.dataSource.data = [];
      this.dataSource.data = resp.cursos || [];
      this.length = Number(resp.cursos?.length) || 0;
    }

    async abrirAsistencia(obj: Curso){
          const dialogRef = this.dialog.open(AsistentesComponent, {
            height: '100%',
            width: '90%',
            data: obj
          });

        dialogRef.afterClosed().subscribe((result: any) => {

        });
    }



}
