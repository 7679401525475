import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CursosService } from '../../../services/cursos.service';
import { UiService } from '../../../services/ui.service';
import { Colegiado } from '../../../../models/colegiado.model';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: []
})
export class ReportesComponent implements OnInit {

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  pages: any [] = [0];
  pageEvent: any = {};
  displayedColumns: string[] = [
    'nombre',
    'fechaInicio',
    'fechaFin',
    'modalidad'
  ];
  colegiado: Colegiado = {};
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);

  length: number = 0;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ReportesComponent>, private ui: UiService, private cursoService: CursosService) {
    this.dataSource = new MatTableDataSource()
  }

  ngOnInit(): void {
    this.colegiado = this.data;
    this.loadData();
  }

  async loadData(){
    const resp = await this.cursoService.getAsistenciaColegiado(this.data._id);
    if (!resp.ok){
      return window.alert(resp.error);
    }

    this.dataSource.data = resp.cursos || [];
  }

}
