<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
            <label>
                El Colegio dispone de un servicio de asistencia laboral para los colegiados que se materializa a través de consultas verbales, entrevistas en la sede colegial o mediante informes escritos. De esta forma se ofrece una cobertura legal a las actuaciones y decisiones de los colegiados con respecto a sus clientes en materia laboral.
                <br>
                El servicio de asistencia laboral se presta por la siguiente persona, Graduado Social:
                <br>
                <strong>
                    D. Manuel Castro Escudero
                </strong>
                <br>
                Las consultas e informes emitidos inciden en todas las parcelas del ejercicio de la profesión de administrador de fincas, destacando las relativas al ámbito laboral.
            </label>
        </mat-card-content>
        </mat-card>

    </div>
</div>

<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
                        <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar consulta ...">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-md="75" class="text-right">
                        <button mat-raised-button (click)="openDialog('Nuevo',{})" [disabled]="permiso !=='COLEG'" color="primary">Nueva consulta</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>



<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

                        <ng-container matColumnDef="fecha">
                            <th mat-header-cell *matHeaderCellDef> Fecha consulta </th>
                            <td mat-cell *matCellDef="let element"> {{element.fecha | date}} </td>
                        </ng-container>

                        <ng-container matColumnDef="solicitante">
                            <th mat-header-cell *matHeaderCellDef> Solicitante </th>
                            <td mat-cell *matCellDef="let element"> {{element.solicitante.nombre}} </td>
                        </ng-container>

                        <ng-container matColumnDef="titulo">
                            <th mat-header-cell *matHeaderCellDef> Título </th>
                            <td mat-cell *matCellDef="let element"> {{element.titulo}} </td>
                        </ng-container>

                        <ng-container matColumnDef="adjuntos">
                            <th mat-header-cell *matHeaderCellDef> Ficheros adjuntos </th>
                            <td mat-cell *matCellDef="let element"> {{element.archivos.length}} 

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="atendido" >
                            <th mat-header-cell *matHeaderCellDef> Atendido </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon style="color: green;" *ngIf="element.atendido">check_box</mat-icon>                                 
                                <mat-icon *ngIf="!element.atendido">check_box_outline_blank</mat-icon>                                 
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Acciones </th>
                            <td mat-cell *matCellDef="let element" class="action-link">
                                <a (click)="openDialog('Actualiza',element)" class="m-r-10 cursor-pointer" *ngIf="permiso ==='COLEG'">
                                    <mat-icon style="color: #8E1537">edit</mat-icon>
                                </a>
                                <a (click)="openDownloadData(element)" class="m-r-10 cursor-pointer" *ngIf="permiso !=='COLEG'">
                                    <mat-icon style="color: green;">cloud_download</mat-icon> </a>
                                    <a (click)="openRespuesta(element)" class="m-r-10 cursor-pointer" *ngIf="permiso ==='COLEG' && element.respuesta.autor">
                                        <mat-icon style="color: green;">chat</mat-icon> </a>
                                <a (click)="openDialog('Eliminar',element)" class="m-r-10 cursor-pointer">
                                   <mat-icon style="color: red" >delete</mat-icon>
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>