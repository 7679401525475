import { Component, OnInit, Optional, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Colegiado } from '../../../models/colegiado.model';

@Component({
  selector: 'app-colegiado-form',
  templateUrl: './colegiado-form.component.html',
  styles: []
})
export class ColegiadoFormComponent {

  action: string;
  local_data: any;
  selectedImage: any = '';


  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<ColegiadoFormComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Colegiado) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    if (this.local_data.fechaNacimiento !== undefined) {
      this.local_data.fechaNacimiento = this.datePipe.transform(new Date(this.local_data.fechaNacimiento), 'yyyy-MM-dd');
    }
    if (this.local_data.fechaAlta !== undefined) {
      this.local_data.fechaAlta = this.datePipe.transform(new Date(this.local_data.fechaAlta), 'yyyy-MM-dd');
    }
    if (this.local_data.ccc){
      this.local_data.iban = this.local_data.ccc.iban || '';
      this.local_data.nc = this.local_data.ccc.nc || '';
    }

    if (this.local_data.fechaSolicitud !== undefined) {
      this.local_data.fechaSolicitud = this.datePipe.transform(new Date(this.local_data.fechaSolicitud), 'yyyy-MM-dd');
    }
    if (this.local_data.avatar === undefined) {
      this.local_data.avatar = 'assets/images/users/default.png';
    }
    console.log(this.action);
  }

  doAction(): void {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  selectFile(event: any): void {
    if (!event.target.files[0] || event.target.files[0].length === 0) {
      // this.msg = 'You must select an image';
      return;
    }
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      // this.msg = "Only images are supported";
      return;
    }
    // tslint:disable-next-line - Disables all
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    // tslint:disable-next-line - Disables all
    reader.onload = (_event) => {
      // tslint:disable-next-line - Disables all
      this.local_data.avatar = reader.result;
    };
  }

  fn_ValidateIBAN(iban: any) {
    //Se pasa a Mayusculas
    iban = iban.toUpperCase();
    //Se quita los blancos de principio y final.
    iban = iban.trim();
    iban = iban.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

    var letra1, letra2, num1, num2;
    var isbanaux;
    var numeroSustitucion;
    //La longitud debe ser siempre de 24 caracteres
    if (iban.length != 24) {
      return false;
    }

    // Se coge las primeras dos letras y se pasan a números
    letra1 = iban.substring(0, 1);
    letra2 = iban.substring(1, 2);
   // num1 = getnumIBAN(letra1);
    // num2 = getnumIBAN(letra2);
    //Se sustituye las letras por números.
    isbanaux = String(num1) + String(num2) + iban.substring(2);
    // Se mueve los 6 primeros caracteres al final de la cadena.
    isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

    //Se calcula el resto, llamando a la función modulo97, definida más abajo
    // const resto = modulo97(isbanaux);
    //if (resto == 1) {
    //  return true;
    // } else {
    //  return false;
   // }
  }
  modulo97(iban: any) {
    var parts = Math.ceil(iban.length / 7);
    var remainer = "";

    for (var i = 1; i <= parts; i++) {
      remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
    }

    return remainer;
  }

  getnumIBAN(letra: any) {
    const ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return ls_letras.search(letra) + 10;
  }


}
