import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment.prod';

const URL = environment.backendUrl;

@Pipe({
  name: 'documento'
})
export class DocumentoPipe implements PipeTransform {

  transform(doc: string, tipo: string): string {
    return `${URL}/documentos/download/${tipo}/${doc}`;
  }

}
