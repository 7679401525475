import { Component, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import globalUser from '../../../../environments/globales';
import { MatDialog } from '@angular/material/dialog';
import { DocumentoFormComponent } from '../../../formularios/documento-form/documento-form.component';
import { Documento } from 'src/models/documento.model';
import { DocumentoService } from '../../../services/documento.service';
import * as fileSaver from 'file-saver';
import { FileService } from '../../../services/file.service';
import { UiService } from '../../../services/ui.service';
import { DateSearchComponent } from '../../../formularios/date-search/date-search.component';

const DOCUMENTOS: Documento[] = [];


@Component({
  selector: 'app-formacion',
  templateUrl: './formacion.component.html',
  styles: []
})
export class FormacionComponent {

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  pageEvent: any;
  pages: any[] = [0];
  displayedColumns: string[] = ['documentos'];
  dataSource: MatTableDataSource<Documento>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  ejercientes: boolean = false;
  length: number = 0;
  dateIni: any;
  dateFin: any;
  filter: any = {};
  dateFilter: boolean = false;

  permiso = globalUser.permiso;


  constructor(private dialog: MatDialog,
    private documentoService: DocumentoService,
    private ui: UiService,
    private fileService: FileService) {
    this.dataSource = new MatTableDataSource();
  }

  ngAfterViewInit(): void {
    this.paginator.pageSize = 10;
    this.loadData();
    this.dataSource.paginator = this.paginator;
  }

  async loadData() {
    this.documentoService.page = 0;
    const data = await this.documentoService.getDocumnetos('FORMACION', undefined, this.filter);
    this.documentoService.page = 1;
    const data1 = await this.documentoService.getDocumnetos('FORMACION', undefined, this.filter);
    if (!data.ok || !data1.ok) {
      window.alert(data.error);
      return;
    } else if (data.documentos && data1.documentos) {
      this.dataSource.data = data.documentos;
      this.dataSource.data.push(...data1.documentos);
      this.length = data.total || 0;
    }
  }


  openDialog(action: string, obj: any) {
    obj.action = action;
    let dialogRef;
    if (action == 'dateSearch'){
      dialogRef = this.dialog.open(DateSearchComponent, {
        data: obj
      });
    } else {
      dialogRef = this.dialog.open(DocumentoFormComponent, {
        data: obj
      });
    }
    dialogRef.afterClosed().subscribe( async result => {
      if (result.event === 'Nuevo') {
        this.addData(result.data);
      } else if (result.event === 'Actualiza') {
        this.updateRowData(result.data);
      } else if (result.event === 'Eliminar') {
        this.deleteRowData(result.data);
      } else if (result.event === 'dateSearch'){
        this.filter.dateIni = result.data.dateIni;
        this.filter.dateFin = result.data.dateFin;
        this.dateFilter = true;
        await this.loadData();
      }
    });

  }

  async desactivaFechas(){
    this.filter = {};
    this.dateFilter = false;
    await this.loadData();
  }
  async addData(data: any) {
    const doc: Documento = {
      tipo: 'FORMACION',
      titulo: data.titulo,
      fechaPubli: new Date(),
      doc: data.doc,
      publicado: true

    }
    const save = await this.documentoService.setDocumento(doc);
    if (!save.ok) {
      window.alert(save.error);
      return;
    }
    this.ui.presentInfo('Documento añadido', 'OK!');
    this.loadData();
  }

  async updateRowData(data: any) {
    const doc = await this.documentoService.updateDocumento(data);
    if (!doc.ok) {
      window.alert(data.error);
      return;
    }
    this.dataSource.data = this.dataSource.data.filter((value: any) => {
      if (value._id === data._id) {
        console.log('Edita en tabla');
        value.titulo = data.titulo;
      }
      return true;
    });
  }

  openElement(doc: string) {
    this.fileService.getDoc('DOCUMENTO', doc).then(r => {
      let blob: any = new Blob([r], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, doc);
    });
  }

  async deleteRowData(data: any) {
    const doc = await this.documentoService.delDocumento(data);
    if (!doc.ok) {
      window.alert(doc.error);
      return;
    }
    this.dataSource.data = this.dataSource.data.filter((value: any) => {
      return value._id !== data._id;
    });
  }

  async applyFilter(filterValue: string) {
      this.searchText = filterValue;
      this.documentoService.page = 0;
      const resp = await this.documentoService.getDocumnetos('FORMACION', this.searchText);
      this.documentoService.page = 1;
      const resp1 = await this.documentoService.getDocumnetos('FORMACION', this.searchText);
      if (!resp.ok && resp1.ok) {
        window.alert(resp.error);
        return;
      }
      if (resp.documentos && resp1.documentos) {
        this.dataSource.data = [];
        this.dataSource.data = resp.documentos;
        this.dataSource.data.push(...resp1.documentos);
        this.length = Number(resp.total) || 0;
      }
    }


  getServerData(event: PageEvent) {
    if (this.pages.includes(event.pageIndex + 1)) {
      return;
    }
    this.pages.push(event.pageIndex + 1);
    this.documentoService.getDocumnetos('FORMACION').then(d => {
      if (!d.ok) {
        window.alert(d.error);
        return;
      }
      if (d.documentos) {
        this.dataSource.data.push(...d.documentos);
      }
    });
  }

}
