import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './services/login.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private routes: Router,
              private loginService: LoginService) { }

  async canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const resp = await this.loginService.verificaLogin();
      if (resp.ok){
        return true;
      } else {
      this.routes.navigate(['login']);
      return false;
    }
  }
}
