import { Routes } from '@angular/router';


import { AuthGuard } from './auth.guard';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ColegiadosComponent } from './pages/colegio/colegiados/colegiados.component';
import { SolicitudesComponent } from './pages/colegio/solicitudes/solicitudes.component';
import { LopdComponent } from './pages/asesoria/lopd/lopd.component';
import { FiscalComponent } from './pages/asesoria/fiscal/fiscal.component';
import { JuridicaComponent } from './pages/asesoria/juridica/juridica.component';
import { LaboralComponent } from './pages/asesoria/laboral/laboral.component';
import { TecnicaComponent } from './pages/asesoria/tecnica/tecnica.component';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { BurofaxComponent } from './pages/utilidades/burofax/burofax.component';
import { ForoComponent } from './pages/utilidades/foro/foro.component';
import { IcredComponent } from './pages/utilidades/icred/icred.component';
import { MantenimientoComponent } from './pages/utilidades/mantenimiento/mantenimiento.component';
import { WebinarComponent } from './pages/utilidades/webinar/webinar.component';
import { CircularesComponent } from './pages/documentacion/circulares/circulares.component';
import { FormacionComponent } from './pages/documentacion/formacion/formacion.component';
import { IpcComponent } from './pages/documentacion/ipc/ipc.component';
import { LegislacionComponent } from './pages/documentacion/legislacion/legislacion.component';
import { TablonComponent } from './pages/documentacion/tablon/tablon.component';
import { CorreoComponent } from './pages/ajustes/correo/correo.component';
import { CuotasComponent } from './pages/ajustes/cuotas/cuotas.component';
import { ComunicacionesComponent } from './pages/comunicaciones/comunicaciones.component';
import { MemoriasComponent } from './pages/colegio/memorias/memorias.component';
import { CursosComponent } from './pages/cursos/cursos.component';
import { ACursosComponent } from './pages/a-cursos/a-cursos.component';


export const AppRoutes: Routes = [

  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'documentacion',
        children: [
          {
            path: 'circulares',
            component: CircularesComponent,
            data: {
              title: 'Circulares y Actas',
              urls: [
                { title: 'Documentación', url: '/documentacion' },
                { title: 'Circulares' }
              ]
            }
          },
          {
            path: 'formacion',
            component: FormacionComponent,
            data: {
              title: 'Formación',
              urls: [
                { title: 'Documentación', url: '/documentacion' },
                { title: 'Formación' }
              ]
            }
          },
          {
            path: 'ipc',
            component: IpcComponent,
            data: {
              title: 'I.P.C.', FormacionComponent,
              urls: [
                { title: 'Documentación', url: '/documentacion' },
                { title: 'I.P.C.' }
              ]
            }
          },
          {
            path: 'legislacion',
            component: LegislacionComponent,
            data: {
              title: 'Legislación',
              urls: [
                { title: 'Documentación', url: '/documentacion' },
                { title: 'Legislación' }
              ]
            }
          },
          {
            path: 'tablon',
            component: TablonComponent,
            data: {
              title: 'Tablón de anuncios',
              urls: [
                { title: 'Documentación', url: '/documentacion' },
                { title: 'Tablón' }
              ]
            }
          },
        ]
      },
      {
        path: 'asesoria',
        children: [
          {
            path: 'lopd',
            component: LopdComponent,
            data: {
              title: 'Aseoría L.O.P.D.',
              urls: [
                { title: 'Asesoría', url: '/asesoria' },
                { title: 'L.O.P.D.' }
              ]
            }
          },
          {
            path: 'fiscal',
            component: FiscalComponent,
            data: {
              title: 'Aseoría fiscal',
              urls: [
                { title: 'Asesoría', url: '/asesoria' },
                { title: 'Fiscal' }
              ]
            }
          },
          {
            path: 'juridica',
            component: JuridicaComponent,
            data: {
              title: 'Aseoría jurídica',
              urls: [
                { title: 'Asesoría', url: '/asesoria' },
                { title: 'Jurídica' }
              ]
            }
          },
          {
            path: 'laboral',
            component: LaboralComponent,
            data: {
              title: 'Aseoría laboral',
              urls: [
                { title: 'Asesoría', url: '/asesoria' },
                { title: 'Laboral' }
              ]
            }
          },
          {
            path: 'tecnica',
            component: TecnicaComponent,
            data: {
              title: 'Aseoría técnica',
              urls: [
                { title: 'Asesoría', url: '/asesoria' },
                { title: 'Técnica' }
              ]
            }
          },
        ]
      },
      {
        path: 'utilidades',
        children: [
          {
            path: 'fax',
            component: BurofaxComponent,
            data: {
              title: 'e-Burofax',
              urls: [
                { title: 'herramientas', url: '/herramientas' },
                { title: 'e-Burofax' }
              ]
            }
          },
          {
            path: 'foro',
            component: ForoComponent,
            data: {
              title: 'Foro',
              urls: [
                { title: 'Herramientas', url: '/herramientas' },
                { title: 'Foro' }
              ]
            }
          },
          {
            path: 'icred',
            component: IcredComponent,
            data: {
              title: 'Plataforma ICRED',
              urls: [
                { title: 'herramientas', url: '/herramientas' },
                { title: 'ICRED' }
              ]
            }
          },
          {
            path: 'guia',
            component: MantenimientoComponent,
            data: {
              title: 'Mantenimiento de edificios',
              urls: [
                { title: 'herramientas', url: '/herramientas' },
                { title: 'Mantenimiento' }
              ]
            }
          },
          {
            path: 'fwebinar',
            component: WebinarComponent,
            data: {
              title: 'Formación Webinar',
              urls: [
                { title: 'herramientas', url: '/herramientas' },
                { title: 'formacion webinar' }
              ]
            }
          },

        ]
      },

      {
        path: 'economico',
        loadChildren: () => import('./pages/economico/economico.module').then(m => m.EconomicoModule)
      },
      {
        path: 'ajustes',
        children: [
          {
            path: 'correo',
            component: CorreoComponent,
            data: {
              title: 'Ajustes de correo',
              urls: [
                { title: 'Ajustes', url: '/ajustes' },
                { title: 'Correo' }
              ]
            }
          },
          {
            path: 'coutas',
            component: CuotasComponent,
            data: {
              title: 'Cuotas',
              urls: [
                { title: 'Ajustes', url: '/ajustes' },
                { title: 'Cuotas' }
              ]
            }
          },

        ]
      },
      {
        path: 'colegio/colegiados',
        component: ColegiadosComponent,
        data: {
          title: 'Colegiados',
          urls: [
            { title: 'Colegio', url: '/colegio' },
            { title: 'Colegiados' }
          ]
        }
      },
      {
        path: 'colegio/solicitudes',
        component: SolicitudesComponent,
        data: {
          title: 'Colegiados',
          urls: [
            { title: 'Colegio', url: '/colegio' },
            { title: 'Solicitudes' }
          ]
        }
      },
      {


        path: 'colegio/memorias',
        component: MemoriasComponent,
        data: {
          title: 'Memorias',
          urls: [
            { title: 'Colegio', url: '/colegio' },
            { title: 'Memorias' }
          ]
        }

      },
      {
        path: 'usuarios',
        component: UsuariosComponent,
        data: {
          title: 'Usuarios',
          urls: [
            { title: 'Administración', url: '/' },
            { title: 'Usuarios' }
          ]
        }
      },
      {
        path: 'cursos',
        component: CursosComponent,
        data: {
          title: 'Cursos',
          urls: [
            { title: 'Administración', url: '/' },
            { title: 'Cursos' }
          ]
        }
      },
      {
        path: 'aCursos',
        component: ACursosComponent,
        data: {
          title: 'Acreditación a cursos',
          urls: [
            { title: 'colegiado', url: '/' },
            { title: 'Acreditacion_a_cursos' }
          ]
        }
      },
      {
        path: 'usuarios',
        component: UsuariosComponent,
        data: {
          title: 'Usuarios',
          urls: [
            { title: 'Administración', url: '/' },
            { title: 'Usuarios' }
          ]
        }
      },
      {
        path: 'comunicaciones',
        component: ComunicacionesComponent,
        data: {
          title: 'Comunicaciones',
          urls: [
            { title: 'Administración', url: '/' },
            { title: 'Comunicaciones' }
          ]
        }
      },
    ]
  },


  { path: 'login', component: LoginComponent },
  { path: 'loginAdmin', component: LoginAdminComponent },
  {
    path: 'authentication', component: AppBlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  },
];


