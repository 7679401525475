import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Usuario, UsuarioResp } from '../../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor( private dataService: DataService) { }

  getUsuarios(){
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.getData('usuarios').then((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }

  getUsuario(_id: string){
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.getData(`usuarios/${_id}`).then((resp: UsuarioResp) => {
        resolve(resp)
      });
    });
  }

  setUsuario(usuario: Usuario){
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.setData('usuarios', usuario).then((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }

  udateUsuario(usuario: Usuario){
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.updateData(`usuarios/${usuario._id}`, usuario).then((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }

  delUsuario(usuario: Usuario) {
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.delData(`usuarios/${usuario._id}`).then((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }
}
