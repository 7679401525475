import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Asesoria, AsesoriaResp } from '../../models/asesoria.model';

@Injectable({
  providedIn: 'root'
})
export class AsesoriaService {

  constructor(private dataService: DataService) { }

  getAsesorias(tipo: string){
    return new Promise<AsesoriaResp>(resolve => {
      this.dataService.getData(`asesorias/${tipo}`).then((resp: AsesoriaResp) => {
        resolve(resp);
      });
    });
  }

  setAsesoria(asesoria: Asesoria){
    return new Promise<AsesoriaResp>(resolve => {
      this.dataService.setData('asesorias', asesoria).then((resp: AsesoriaResp) => {
        resolve(resp);
      });
    });
  }

  updateAsesoria(asesoria: Asesoria) {
    return new Promise<AsesoriaResp>(resolve => {
      this.dataService.updateData(`asesorias/${asesoria._id}`, asesoria).then((resp: AsesoriaResp) => {
        resolve(resp);
      });
    });
  }

  deleteAsesoria(asesoria: Asesoria){
    return new Promise<AsesoriaResp>(resolve => {
      this.dataService.delData(`asesorias/${asesoria._id}`).then((resp: AsesoriaResp) => {
        resolve(resp);
      });
    });
  }



}
