import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { DocumentoResp, Documento } from '../../models/documento.model';


@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  page: number = 0;

  constructor(private dataService: DataService) { }

  getDocumnetos(tipo: string, buscar?: string, filter?: any){
    this.page ++;
    return new Promise<DocumentoResp>(resolve => {
      this.dataService.setData(`documentos/get/${tipo}?page=${this.page}&buscar=${buscar}`, filter).then((resp: DocumentoResp) => {
        resolve(resp);
      });
    });
  }

  setDocumento(documento: Documento){
    return new Promise<DocumentoResp>(resolve => {
      this.dataService.setData(`documentos`, documento).then((resp: DocumentoResp) => {
        resolve(resp);
      });
    });
  }

  updateDocumento(documento: Documento){
    return new Promise<DocumentoResp>(resolve => {
      this.dataService.updateData(`documentos/${documento._id}`, documento).then((resp: DocumentoResp) => {
        resolve(resp);
      });
    });
  }

  delDocumento(documento: Documento){
    return new Promise<DocumentoResp>(resolve => {
      this.dataService.delData(`documentos/${documento._id}`).then((resp: DocumentoResp) => {
        resolve(resp);
      });
    });
  }
}
