import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Usuario } from '../../../models/usuario.model';
import { UsuarioService } from '../../services/usuario.service';
import { UsuarioFormComponent } from '../../formularios/usuario-form/usuario-form.component';
import { UiService } from '../../services/ui.service';


const USUARIOS: Usuario[] = [];

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styles: []
})
export class UsuariosComponent implements AfterViewInit{

  dataSource: MatTableDataSource<Usuario>;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  displayedColumns: string[] = ['nombre', 'email', 'telefono', 'provincia', 'action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);

  constructor(private dialog: MatDialog,
    private usuarioService: UsuarioService,
    private ui: UiService) {
    this.dataSource = new MatTableDataSource((USUARIOS));
  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.loadData();
  }
  async loadData() {
    const resp = await this.usuarioService.getUsuarios();
    if (!resp.ok) {
      alert(resp.error);
      return;
    }
    if (resp.usuarios) {
      USUARIOS.push(...resp.usuarios);
      this.dataSource.data = resp.usuarios;

    }
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(UsuarioFormComponent, {
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Nuevo') {
        this.usuarioAdd(result.data);
      } else if (result.event === 'Actualiza') {
        this.updateRowData(result.data);
      } else if (result.event === 'Eliminar') {
        this.deleteRowData(result.data);
      } else {
        console.log('Exit');
      }
    });
  }

  addRowData(row_obj: Usuario): void {
    this.dataSource.data.push(row_obj);
    this.table.renderRows();
  }

  async updateRowData(row_obj: Usuario){
    const colegiadoUpdate = await this.usuarioService.udateUsuario(row_obj);
    if (colegiadoUpdate.ok){
      this.dataSource.data = this.dataSource.data.filter((value: any) => {
        if (value._id === row_obj._id) {
          value.nombre = row_obj.nombre;
          value.email = row_obj.email;
          value.telefono = row_obj.telefono;
          value.provincia = row_obj.provincia;
          // value.avatar = row_obj.avatar;
        }
        return true;
      });
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async usuarioAdd(data: Usuario){
    const newUsuario = await this.usuarioService.setUsuario(data);
    if (!newUsuario.ok){
      window.alert(newUsuario.error);
      return
    } else if (newUsuario.usuario){
      USUARIOS.push(newUsuario.usuario);
      this.ui.presentInfo('Usuario creado correctamente', 'OK!');
      this.loadData();
    }
  }
  async deleteRowData(row_obj: Usuario) {
    const deleteColegiado = await this.usuarioService.delUsuario(row_obj);
    if (deleteColegiado.ok){
      this.dataSource.data = this.dataSource.data.filter((value: any) => {
        return value._id !== row_obj._id;
      });
    }
  }
}
