import { Component, OnInit, Optional, Inject } from '@angular/core';
import { Asesoria, Respuesta } from '../../../models/asesoria.model';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from '../../services/file.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-asesoria',
  templateUrl: './asesoria.component.html',
  styles: []
})
export class AsesoriaComponent implements OnInit {

  local_data: Asesoria;
  respuesta: Respuesta = {}

  constructor(public datePipe: DatePipe,
              private fileService: FileService,
              public dialogRef: MatDialogRef<AsesoriaComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: Asesoria) { 
                this.local_data = {...data};   
                if (this.local_data.respuesta){
                  this.respuesta = this.local_data.respuesta;
                }
              }

  ngOnInit(): void {

  }

  closeDialog(){
    this.dialogRef.close();
  }

  async downloadDoc(doc: string){
    this.fileService.getDoc('LOPD', doc).then(r => {
      let blob:any = new Blob([r], { type: 'text/json; charset=utf-8' });
			const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, doc);
    });
  }

  responder(){
    this.local_data.respuesta = this.respuesta;
    this.dialogRef.close({data: this.local_data });
  }

}
