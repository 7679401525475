import { Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ColegiadoFormComponent } from '../colegiado-form/colegiado-form.component';
import { ColegiadoService } from '../../services/colegiado.service';

@Component({
  selector: 'app-solicitar-usuario',
  templateUrl: './solicitar-usuario.component.html',
  styles: []
})
export class SolicitarUsuarioComponent {

  dni: string = '';
  nterritorial = 0; 
  constructor(
    private colegiadoService: ColegiadoService,
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<ColegiadoFormComponent>) {
  }

  async doAction() {
    const resp = await this.colegiadoService.solicitaAcceso(this.dni, this.nterritorial);
    if (!resp.ok){
      window.alert(resp.error);
    }
    this.dialogRef.close({event: 'Success'});
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }


}
