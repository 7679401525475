import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  acceso: boolean = false;

  constructor( private navCtrl: Router){
    
    this.navCtrl.navigate(['/login']);
  }

}

