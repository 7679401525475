import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { ColegiadoFormComponent } from '../formularios/colegiado-form/colegiado-form.component';
import { Colegiado } from '../../models/colegiado.model';
import { ColegiadoService } from '../services/colegiado.service';
import { UiService } from '../services/ui.service';
import { SolicitarUsuarioComponent } from '../formularios/solicitar-usuario/solicitar-usuario.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [],
  providers: [LoginService]
})
export class LoginComponent {
  msg = '';
  twofa: boolean = false;
  uname = '';
  constructor(private service: LoginService,
    private routes: Router,
    private dialog: MatDialog,
    private ui: UiService,
    private colegiadoService: ColegiadoService) { }

  // tslint:disable-next-line: typedef
  /*
  async check(p: string, uname?: string, ) {

    if (!this.twofa) {
      const output = await this.service.loginUser(uname || '', p);
      if (output) {
        this.uname = uname || '';
        this.twofa = true;
        this.msg = 'Ingrese el código recibido por email para acceder';
      } else {
        this.msg = 'Usuario o contraseña incorrectos';
      }
    } else {
      const access = await this.service.validaTwoFactor(this.uname, p);
      if (!access) {
        this.routes.navigate(['/loginAdmin']);
        return;
      } else {
        this.routes.navigate(['/colegio/colegiados']);
      }
    }
  }
  */
  async check(p: string, uname?: string) {
    if (!this.twofa) {
      const output = await this.service.loginColegio(uname || '' , p);
      if (output) {
        this.uname = uname || '';
        this.twofa = true;
        this.msg = 'Ingrese el código recibido por email para acceder';
      } else {
        this.msg = 'DATOS INVALIDOS O USUARIO DESACTIVADO';
      }
    } else {
      const access = await this.service.validaTwoFactorColegio(this.uname, p);
      if (!access) {
        this.routes.navigate(['/login']);
        return;
      } else {
        this.routes.navigate(['/documentacion']);
      }
    }
  }
  nuevoColegiado(action: string, obj: any): void {
    obj.action = action;
    obj.solicitud = true;
    const dialogRef = this.dialog.open(SolicitarUsuarioComponent, {
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Solicitud') {
        this.colegiadoAdd(result.data);
      }
    });
  }

  async colegiadoAdd(data: any) {
    const coleg: Colegiado = {
      nombre: data.nombre,
      dni: data.dni,
      nTerritorial: data.nTerritorial,
      nNacional: data.nNacional,
      fechaNacimiento: new Date(data.fechaNacimiento),
      lugarNacimiento: data.lugarNacimiento,
      direccionParticular: data.direccionParticular,
      poblacionParticular: data.poblacionParticular,
      provinciaParticular: data.provinciaParticular,
      cpParticular: data.cpParticular,
      tituloUni: data.tituloUni,
      direccionProf: data.direccionProf,
      poblacionProf: data.poblacionProf,
      provinciaProf: data.provinciaProf,
      cpProf: data.cpProf,
      tlfFijo: data.tlfFijo,
      tlfMovil: data.tlfMovil,
      fechaAlta: new Date(),
      ejerciente: data.ejerciente,
      mAcceso: data.mAcceso || 'TITULO',
      personaLegal: data.personaLegal,
      epg: data.epg,
      razonSocial: data.razonSocial,
      negligencia: data.negligencia,
      importeNegReclamado: data.importeNegReclamado,
      importeNegRecservas: data.importeNegRecservas,
      importeNegPagos: data.importeNegPagos,
      futuraReclamacion: data.futuraReclamacion,
      siReclamacion: data.siReclamacion,
      titularCuenta: data.titularCuenta,
      ccc: { iban: data.iban, nc: data.nc },
      periodoPago: data.periodoPag,
      firma: data.firma || '',
      email: data.email,
      password: data.password || '123456',
      avatar: data.avatar || '',
      eliminado: data.eliminad,
    }
    const newColegiado = await this.colegiadoService.setColegiado(coleg);
    if (!newColegiado.ok) {
      window.alert('Error al crear la solicitud, contacte con nosotros para solucionar el problema. Gracias!')
      return;
    } else if (newColegiado.colegiado) {
      this.ui.presentInfo('Sulicitud dada de alta correctamente', 'OK!');
    }
  }

  adminAccess() {
    this.routes.navigate(['/loginAdmin']);
  }


}
