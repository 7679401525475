import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icred',
  templateUrl: './icred.component.html',
  styles: []
})
export class IcredComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onClick(acceso: string){
    if (acceso === 'plataforma'){
      window.open('http://www.cafincas.org/?option=com_content&view=article&id=95', '_blank');
    } else if (acceso = 'acceso'){
      window.open('https://ayuda.iciredimpagados.com/hc/es-es/articles/360011243020', '_blank');
    }
  }

}
