<h2 class="font-medium" mat-dialog-title>
  <strong>Cambio de contraseña colegiado</strong>
</h2>

<mat-dialog-content>
  <form #userForm="ngForm">

    <div fxLayout="row wrap" class="align-items-center">

        <div fxFlex="100" fxFlex.gt-md="50">
            <div class="m-r-15 m-l-15">
                <mat-form-field>
                    <input type="password" minlength="5" matInput [required]="true" id="password" name="password" [(ngModel)]="local_data.password" placeholder="Contraseña, mínimo 5 caracteres">
                </mat-form-field>
            </div>
        </div>
        <div fxFlex="100" fxFlex.gt-md="50">
            <div class="m-r-15 m-l-15">
                <mat-form-field>
                    <input type="password" minlength="5" matInput [required]="true" id="password" name="passwordConfirm" [(ngModel)]="local_data.passwordConfirm" placeholder="Repita la contraseña">
                </mat-form-field>
            </div>
        </div>


    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!userForm.valid || local_data.password != local_data.passwordConfirm">Actualizar</button>
    <button mat-button (click)="closeDialog()">Cancel</button>
</form>
</mat-dialog-content>
