import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login-admin.component.html',
  styles: [],
  providers: [LoginService]
})
export class LoginAdminComponent {
  msg = '';
  twofa: boolean = false;
  uname = '';
  constructor(private service: LoginService,
    private routes: Router) { }

  // tslint:disable-next-line: typedef
  async check(p: string, uname?: string, ) {

    if (!this.twofa) {
      const output = await this.service.loginUser(uname || '', p);
      if (output) {
        this.uname = uname || '';
        this.twofa = true;
        this.msg = 'Ingrese el código recibido por email para acceder';
      } else {
        this.msg = 'Usuario o contraseña incorrectos';
      }
    } else {
      const access = await this.service.validaTwoFactor(this.uname, p);
      if (!access) {
        this.routes.navigate(['/loginAdmin']);
        return;
      } else {
        this.routes.navigate(['/colegio/colegiados']);
      }
    }
  }

  nuevoColegiado(action: string, obj: any): void {
    // obj.action = action;
    // const dialogRef = this.dialog.open(ColegiadoFormComponent, {
    //   data: obj
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result.event === 'Solicitud') {

    //   }
    // });
  }

  access() {
    this.routes.navigate(['/loginAdmin']);
  }


}
