import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Asesoria } from '../../../../models/asesoria.model';
import { MatPaginator } from '@angular/material/paginator';
import globalUser from '../../../../environments/globales';
import { MatDialog } from '@angular/material/dialog';
import { AsesoriaService } from '../../../services/asesoria.service';
import { AsesoriaFormComponent } from '../../../formularios/asesoria-form/asesoria-form.component';
import { UiService } from '../../../services/ui.service';

const ASESORIAS: Asesoria[] = [];


@Component({
  selector: 'app-fiscal',
  templateUrl: './fiscal.component.html',
  styles: []
})
export class FiscalComponent  {

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  displayedColumns: string[] = ['fecha', 'solicitante', 'titulo', 'adjuntos', 'atendido', 'action'];
  dataSource: MatTableDataSource<Asesoria>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  ejercientes: boolean = false;
  permiso = globalUser.permiso;

  constructor(private dialog: MatDialog,
              private asesoriaService: AsesoriaService,
              private ui: UiService
            ) {
    this.dataSource = new MatTableDataSource((ASESORIAS));
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.loadData();
  }

  async loadData() {
    const resp = await this.asesoriaService.getAsesorias('FISCAL');
    if (!resp.ok) {
      alert(resp.error);
      return;
    }
    if (resp.asesorias) {
      ASESORIAS.push(...resp.asesorias);
      this.dataSource.data = resp.asesorias;
    }
  }


  openDialog(action: string, obj: any): void {
    obj.action = action;
    obj.tipo = 'FISCAL';
    const dialogRef = this.dialog.open(AsesoriaFormComponent, {
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Nuevo') {
        this.asesoriaAdd(result.data);
      } else if (result.event === 'Actualiza') {
        this.updateRowData(result.data);
      } else if (result.event === 'Eliminar') {
        this.deleteRowData(result.data);
      } else {

      }
    }); 
  }

  addRowData(row_obj: Asesoria): void {
    this.dataSource.data.push(row_obj);
    // this.dialog.open(AddComponent);
    this.table.renderRows();
  }

  async updateRowData(data: Asesoria) {
    const update = await this.asesoriaService.updateAsesoria(data);  
      if(update.ok){
        this.loadData();
        return true;    
      } 
  }

  async deleteRowData(row_obj: Asesoria) {
    const deleteColegiado = await this.asesoriaService.deleteAsesoria(row_obj);
    if (deleteColegiado.ok) {
      this.dataSource.data = this.dataSource.data.filter((value: any) => {
        return value._id !== row_obj._id;
      });
    }
  }

  async asesoriaAdd(data: any) {
    const assesoria: Asesoria = {
      titulo: data.titulo,
      mensaje: data.mensaje,
      fecha: new Date(),
      tipo: 'FISCAL',
      archivos: data.archivos  
    }
     const newLOPD = await this.asesoriaService.setAsesoria(assesoria);
     if (!newLOPD.ok){
       window.alert(newLOPD.error);
       return;
    }
    this.ui.presentInfo('Solicitud creada correctamente', 'OK!');
    this.loadData();
    
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  
}
