<h2 class="font-medium" mat-dialog-title><strong>Datos de asistencia al curso {{curso}}</strong></h2>

<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
                        <mat-form-field>
                            <input matInput (keydown.enter)="applyFilter($event.target.value)" placeholder="Buscar curso ...">
                        </mat-form-field>
                        <div>
                            <button mat-button (click)="loadData()">
                            <mat-icon style="color: #3239f5">autorenew</mat-icon>
                            Reload
                        </button>
                        </div>
                        <div fxFlex.gt-md="20" class="text-right">
                            <button mat-raised-button (click)="exportXls()" color="primary">
                         Exportar XLS
                        </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

                        <ng-container matColumnDef="dni">
                            <th mat-header-cell *matHeaderCellDef> DNI / NIF </th>
                            <td mat-cell *matCellDef="let element"> {{element.colegiado.dni}} </td>
                        </ng-container>
                        <ng-container matColumnDef="nombre">
                            <th mat-header-cell *matHeaderCellDef> Nombre </th>
                            <td mat-cell *matCellDef="let element"> {{element.colegiado.nombre.toUpperCase()}} </td>
                        </ng-container>
                        <ng-container matColumnDef="nterritorial">
                            <th mat-header-cell *matHeaderCellDef> Número Territorial </th>
                            <td mat-cell *matCellDef="let element"> {{element.colegiado.nTerritorial}} </td>
                        </ng-container>
                        <ng-container matColumnDef="asistencia">
                            <th mat-header-cell *matHeaderCellDef> Asistencia completada </th>
                            <td mat-cell *matCellDef="let element"> {{element.asistencia ? 'SI': 'NO'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="accion">
                            <th mat-header-cell *matHeaderCellDef> Acciones </th>
                            <td mat-cell *matCellDef="let element" class="action-link">
                                <button mat-icon-button (click)="asiste(element)" matTooltip="Asistencia completada" class="m-r-10 cursor-pointer">
                                  <mat-icon>assignment_ind</mat-icon>
                              </button>
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #paginator [length]="length" [pageSize]="10" (page)="pageEvent = $event"></mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
