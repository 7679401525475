import { Component,  AfterViewInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import { Colegiado, CCC } from '../../../../models/colegiado.model';

import { ColegiadoService } from '../../../services/colegiado.service';
import { ColegiadoFormComponent } from '../../../formularios/colegiado-form/colegiado-form.component';
import { UiService } from '../../../services/ui.service';
import { ExportXlsService } from '../../../services/export-xls.service';
import { ReportesComponent } from '../../cursos/reportes/reportes.component';


// const COLEGIADOS: Colegiado[] = [];


@Component({
  selector: 'app-colegiados',
  templateUrl: './colegiados.component.html',
  styles: []
})
export class ColegiadosComponent implements AfterViewInit {

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  pages: any [] = [0];
  pageEvent: any = {};
  displayedColumns: string[] = ['dni', 'nombre', 'email', 'tlfMovil', 'fechaSolicitud', 'poblacion', 'nTerritorial', 'action'];
  dataSource: MatTableDataSource<Colegiado>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  ejercientes: boolean = false;
  length: number = 0;


  constructor(private dialog: MatDialog,
    private colegiadoService: ColegiadoService,
    private xlsService: ExportXlsService,
    private ui: UiService) {
    this.dataSource = new MatTableDataSource();
  }

  ngAfterViewInit(): void {
    this.paginator.pageSize = 10;
    this.loadData();
    this.dataSource.paginator = this.paginator;
  }

  async loadData() {
    this.colegiadoService.page = 0;
    const resp = await this.colegiadoService.getColegiados(this.ejercientes);
    this.colegiadoService.page = 1;
    const resp1 = await this.colegiadoService.getColegiados(this.ejercientes);
    if (!resp.ok && !resp1.ok) {
      window.alert(resp.error);
      return;
    }
    if (resp.colegiados && resp1.colegiados) {
      this.dataSource.data = [];
      this.dataSource.data = resp.colegiados;
      this.dataSource.data.push(...resp1.colegiados);
      this.length = Number(resp.total) || 0;
    }
  }


  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(ColegiadoFormComponent, {
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Nuevo') {
        this.colegiadoAdd(result.data);
      } else if (result.event === 'Actualiza') {
        this.updateRowData(result.data);
      } else if (result.event === 'Eliminar') {
        this.deleteRowData(result.data);
      } else {
      }
    });
  }

  // Obtiene siguientes registros de la base de datos. importante mandar la longitud
  getServerData(event: PageEvent){
      if (this.pages.includes(event.pageIndex +1)){
        return;
      }
      this.pages.push(event.pageIndex +1);
      this.colegiadoService.getColegiados(this.ejercientes, this.searchText).then( c => {
        if (!c.ok) {
          window.alert(c.error);
          return;
        }
        if (c.colegiados) {
          this.dataSource.data.push(...c.colegiados);
        }
      });
  }

  addRowData(row_obj: Colegiado): void {
    this.dataSource.data.push(row_obj);
    // this.dialog.open(AddComponent);
    this.table.renderRows();
  }

  async updateRowData(data: Colegiado) {
    if (!data.estado){
      data.fechaBaja = new Date();
    }
    const colegiadoUpdate = await this.colegiadoService.udateColegiado(data);
    if (!data.estado){
      this.dataSource.data = this.dataSource.data.filter((value: any) => {
        return value._id !== data._id;
      });
    } else {
      if (colegiadoUpdate.ok) {
        this.dataSource.data = this.dataSource.data.filter((value: any) => {
          if (value._id === data._id) {
            value.nombre = data.nombre;
            value.dni = data.dni;
            value.nTerritorial = data.nTerritorial;
            value.nNacional = data.nNacional;
            value.fechaNacimiento = data.fechaNacimiento;
            value.lugarNacimiento = data.lugarNacimiento;
            value.direccionParticular = data.direccionParticular;
            value.poblacionParticular = data.poblacionParticular;
            value.provinciaParticular = data.provinciaParticular;
            value.cpParticular = data.cpParticular;
            value.tituloUni = data.tituloUni;
            value.direccionProf = data.direccionProf;
            value.poblacionProf = data.poblacionProf;
            value.provinciaProf = data.provinciaProf;
            value.cpProf = data.cpProf;
            value.tlfFijo = data.tlfFijo;
            value.tlfMovil = data.tlfMovil;
            value.fechaAlta = data.fechaAlta;
            value.ejerciente = data.ejerciente;
            value.mAcceso = data.mAcceso || 'TITULO';
            value.personaLegal = data.personaLegal;
            value.epg = data.epg;
            value.razonSocial = data.razonSocial;
            value.negligencia = data.negligencia;
            value.importeNegReclamado = data.importeNegReclamado;
            value.importeNegRecservas = data.importeNegRecservas;
            value.importeNegPagos = data.importeNegPagos;
            value.futuraReclamacion = data.futuraReclamacion;
            value.siReclamacion = data.siReclamacion;
            value.titularCuenta = data.titularCuenta;
            value.iban = data.ccc?.iban;
            value.nc = data.ccc?.nc;
            value.periodoPago = '';
            value.firma = data.firma || '';
            value.email = data.email;
            value.avatar = data.avatar || '';
            value.eliminado = data.eliminado;
          }
          return true;
        });
    }

    }
  }

  async deleteRowData(row_obj: Colegiado) {
    const deleteColegiado = await this.colegiadoService.delColegiado(row_obj);
    if (deleteColegiado.ok) {
      this.dataSource.data = this.dataSource.data.filter((value: any) => {
        return value.dni !== row_obj.dni;
      });
    }
  }

  async colegiadoAdd(data: any) {
    const coleg: Colegiado = {
      nombre: data.nombre,
      dni: data.dni,
      nTerritorial: data.nTerritorial,
      nNacional: data.nNacional,
      fechaNacimiento: new Date(data.fechaNacimiento),
      lugarNacimiento: data.lugarNacimiento,
      direccionParticular: data.direccionParticular,
      poblacionParticular: data.poblacionParticular,
      provinciaParticular: data.provinciaParticular,
      cpParticular: data.cpParticular,
      tituloUni: data.tituloUni,
      direccionProf: data.direccionProf,
      poblacionProf: data.poblacionProf,
      provinciaProf: data.provinciaProf,
      cpProf: data.cpProf,
      tlfFijo: data.tlfFijo,
      tlfMovil: data.tlfMovil,
      fechaAlta: new Date(),
      ejerciente: data.ejerciente,
      mAcceso: data.mAcceso || 'TITULO',
      personaLegal: data.personaLegal,
      epg: data.epg,
      razonSocial: data.razonSocial,
      negligencia: data.negligencia,
      importeNegReclamado: data.importeNegReclamado,
      importeNegRecservas: data.importeNegRecservas,
      importeNegPagos: data.importeNegPagos,
      futuraReclamacion: data.futuraReclamacion,
      siReclamacion: data.siReclamacion,
      titularCuenta: data.titularCuenta,
      ccc: { iban: data.iban, nc: data.nc },
      periodoPago: data.periodoPag,
      firma: data.firma || '',
      email: data.email,
      password: data.password || '123456',
      avatar: data.avatar || '',
      eliminado: data.eliminad,
      estado: true
    }
    const newColegiado = await this.colegiadoService.setColegiado(coleg);
    if (!newColegiado.ok){
      window.alert(newColegiado.error);
      return;
    } else if (newColegiado.colegiado) {
      this.addRowData(newColegiado.colegiado);
      this.ui.presentInfo('Colegiado dado de alta corectamente', 'OK!');
      this.loadData();
    }
  }

  async applyFilter(filterValue: string) {
    this.searchText = filterValue;
    this.colegiadoService.page = 0;
    const resp = await this.colegiadoService.getColegiados(this.ejercientes, this.searchText);
    this.colegiadoService.page = 1;
    const resp1 = await this.colegiadoService.getColegiados(this.ejercientes, this.searchText);
    if (!resp.ok && resp1.ok) {
      window.alert(resp.error);
      return;
    }
    if (resp.colegiados && resp1.colegiados) {
      this.dataSource.data = [];
      this.dataSource.data = resp.colegiados;
      this.dataSource.data.push(...resp1.colegiados);
      this.length = Number(resp.total) || 0;
    }
  }

  async export(){
    if (!await this.xlsService.exportColegiados(this.ejercientes)) return window.alert('Erro al ezportar los colegiados');
  }

  async openCursos(element: any){
    const dialogRef = this.dialog.open(ReportesComponent, {
      data: element,
      height: '50%',
      width: '60%',
    });
  }


}

