<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                        <label>
                            Sigue realizando tus envíos <b>Logalty</b>, con total garantía jurídica, ahora por SEUR BUROFAX (servicio de tercero confianza transmitido por Logalty), a mejor precio conforme el acuerdo suscrito entre, una de las principales firmas de mensajería, como es SEUR y tu Colegio.
                        </label>
                    </div>

                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row wrap" class="align-items-center">
                    <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                        <h2 style="color: #8E1537;">Importante</h2>
                        <label>
                            Antes de acceder por primera vez, debes activar tu alta como cliente, adhiriéndote al convenio vigente de colaboración, para ofrecerte las mejores prestaciones y precios.
                        </label>
                        <br>
                        <br>
                        <label>
                            Cumplimenta este <a style="color: #8E1537; text-decoration-line: underline;" href="http://www.cafsevilla.com/wp-content/uploads/2016/08/impreso-alta-seur-burofax.pdf">este documento</a> y envíalo al siguiente correo electrónico <a style="color: #8E1537;">ana.macias@seur.net</a>
                        </label>
                        <br>
                        <br>
                        <label>
                            (Cualquier duda para cursar tu alta-cliente CAFS, dispones de la comercial SEUR Ana Macias Pimentel en el <a style="color: #8E1537;">Tlf. 677 991 991</a>)
                        </label>
                        <br>
                        <br>
                        <label style="color: #8E1537;">
                            Nota: Para consultar documentación del historial de los expedientes tramitados con anterioridad, debes efectuarla por vía de correo electrónico a  atencioncliente@logalty.com  o  cau@logalty.com , indicando el código GUID de la transacción.
                        </label>
                    </div>

                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div fxLayout="row wrap">
    <div fxFlex="100">
                <div fxLayout="row wrap" style="text-align: center;">
                    <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
                        <button mat-button (click)="openBurofax()">
                            <img src="../../../assets/images/button-e-burofax.jpg">
                        </button>
                    </div>

                </div>
    </div>
</div>
