import { Component,  Optional, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-respuesta-asesoria',
  templateUrl: './respuesta-asesoria.component.html',
  styles: []
})
export class RespuestaAsesoriaComponent {

  local_data: any;

  constructor(public datePipe: DatePipe,
              public dialogRef: MatDialogRef<RespuestaAsesoriaComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { 
                this.local_data = {...data};                
              }
  cerrar(){
    this.dialogRef.close();
  }
}
