import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-date-search',
  templateUrl: './date-search.component.html',
})
export class DateSearchComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DateSearchComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {}

  dateIni: any;
  dateFin: any;

  ngOnInit(): void {
  }

  doAction(){
    if (!this.dateFin){
      this.dateFin = new Date(new Date(this.dateIni).getTime() + 24 * 60 * 60 * 1000);
    }
    this.dialogRef.close({event: 'dateSearch', data: {dateIni: this.dateIni, dateFin: this.dateFin}});
  }
closeDialog(){
  this.dialogRef.close();
}

}
