 
<mat-dialog-content>
    <div fxLayout="row wrap" class="align-items-center">
        <div fxFlex="10" fxFlex.gt-md="10">
            <img style="height: 45px;" src="../../assets/images/logocaf.png">
        </div>
        <div fxFlex="90" fxFlex.gt-md="90">
            <h3 class="font-medium" mat-dialog-title style="text-align: center;">
                <strong>{{local_data.titulo}}</strong>
            </h3>
        </div>
    </div>
    <quill-view [content]="local_data.mensaje"></quill-view>
   
    <button mat-button (click)="cerrar()" mat-flat-button color="warn">Cerrar</button>
</mat-dialog-content>