<h2 class="font-medium" mat-dialog-title><strong>{{action}} Consulta</strong></h2>
<mat-dialog-content *ngIf="action != 'Eliminar'; else elseTemplate">
    <form #userForm="ngForm">   
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex="100" fxFlex.gt-md="100">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="titulo" name="titulo" [(ngModel)]="local_data.titulo" placeholder="Título">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100">
                <div class="m-r-15 m-l-15">
                    <mat-form-field [style.fontSize]="12">
                        <mat-label>Mensaje</mat-label>
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="5"
                            required
                            name="mensaje"
                            [(ngModel)]="local_data.mensaje"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex.gt-sm="100%">
                <mat-card>
                    <button mat-raised-button color="primary">
                        <mat-card-title>Documentos adjuntos</mat-card-title>
                        <input type="file" (change)="selectFile($event)" #fileInput>
                    </button>
                    <mat-card-content>
                        
                        <mat-list role="list">
                          <mat-list-item *ngFor="let doc of documentos" role="listitem">{{doc.original}}
                         <!-- 
                        -->    
                        <button mat-icon-button (click)="downloadDoc(doc.original)">
                                <mat-icon class="mat-24" style="color: green;">cloud_download</mat-icon>
                              </button>
                                
                                <button mat-icon-button (click)="removeDoc(doc.subido)">
                                    <mat-icon class="mat-24" style="color: red;">delete</mat-icon>
                                      </button>
                            
                          </mat-list-item>
                        </mat-list>
                        
                    </mat-card-content>
                      
                </mat-card>
            </div>

        </div>
        <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!userForm.valid">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </form>
</mat-dialog-content>
<ng-template #elseTemplate>
    <p>¿Esta seguro que desa eliminar a <b>{{local_data.nombre}}</b> ?</p>
    <div mat-dialog-actions align="end" class="pt-3">
        <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </div>
</ng-template>
