<h2 class="font-medium" mat-dialog-title>
    <strong>{{action}} Colegiado</strong>
</h2>



<mat-dialog-content *ngIf="action != 'Eliminar'; else elseTemplate">
    <form #userForm="ngForm">
        <div class="d-flex align-items-center m-b-15">
            <img class="mat-card-avatar" [src]='local_data.avatar'>
            <button mat-raised-button color="primary" class="m-l-15 input-file-button">
                <input type="file" (change)="selectFile($event)" #fileInput>
            </button>
        </div>
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex="100" fxFlex.gt-md="75">
                <h3>Datos personales</h3>
                <mat-slide-toggle *ngIf="!local_data.solicitud" name="estado" [(ngModel)]="local_data.estado">Colegiado activo
                </mat-slide-toggle>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="date" placeholder="Fecha alta" matInput required id="fechaAlta" name="fechaAlta" [(ngModel)]="local_data.fechaAlta">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="name" name="name" [(ngModel)]="local_data.nombre" placeholder="Nombre">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="dni" name="dni" [(ngModel)]="local_data.dni" placeholder="D.N.I.">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput required id="email" name="email" [(ngModel)]="local_data.email" placeholder="Email">
                    </mat-form-field>
                </div>

            </div>
            <div fxFlex="100" fxFlex.gt-md="12.5">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="phone" name="phone" [(ngModel)]="local_data.tlfFijo" placeholder="Fijo">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="12.5">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="mobile" name="mobile" [(ngModel)]="local_data.tlfMovil" placeholder="Móvil">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100" *ngIf="action ==='Solicitud'">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="password" matInput id="pass" name="pass" [(ngModel)]="local_data.password" placeholder="Contraseña">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="date" placeholder="Fecha nacimiento" matInput id="fechaNacimiento" name="fechaNacimiento" [(ngModel)]="local_data.fechaNacimiento">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="lnacimiento" name="lnacimiento" [(ngModel)]="local_data.lugarNacimiento" placeholder="Lugar Nacimiento">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="dirPer" name="dirPer" [(ngModel)]="local_data.direccionParticular" placeholder="Dirección personal">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="pobrPer" name="pobPer" [(ngModel)]="local_data.poblacionParticular" placeholder="Población personal">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="provrPer" name="provPer" [(ngModel)]="local_data.provinciaParticular" placeholder="Provincia personal">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="number" matInput id="cpPer" name="cpPer" [(ngModel)]="local_data.cpParticular" placeholder="Código postal">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100">
                <h3>Datos académicos y profesionales</h3>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="estudios" name="estudios" [(ngModel)]="local_data.tituloUni" placeholder="Título universitário">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="dirProf" name="dirProf" [(ngModel)]="local_data.direccionProf" placeholder="Dirección profesional">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="pobrProf" name="pobProf" [(ngModel)]="local_data.poblacionProf" placeholder="Población profesional">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="provrProf" name="provProf" [(ngModel)]="local_data.provinciaProf" placeholder="Provincia profesional">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="number" matInput id="cpProf" name="cpProf" [(ngModel)]="local_data.cpProf" placeholder="Código postal">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="25" *ngIf="ancion !== Solicitud">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="date" placeholder="Fecha solicitud" matInput id="fechaSolicitud" name="fechaSolicitud" [(ngModel)]="local_data.fechaSolicitud">
                    </mat-form-field>
                </div>
            </div>

            <div fxFlex="100" fxFlex.gt-md="20">
                <div class="m-r-15 m-l-15">
                    <section>
                        <label style="margin-left: 10px;">Ejerciente:</label>
                        <mat-radio-group name="ejerciente" [(ngModel)]="local_data.ejerciente">
                            <mat-radio-button style="margin-left: 10px;" color="primary" [value]="true">
                                SÍ
                            </mat-radio-button>
                            <mat-radio-button style="margin-left: 10px;" color="primary" [value]="false">
                                NO
                            </mat-radio-button>
                        </mat-radio-group>
                    </section>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="27">
                <div class="m-r-15 m-l-15" *ngIf="local_data.ejerciente">
                    <section>
                        <label style="margin-left: 10px;">Tipo jurídico:</label>
                        <mat-radio-group name="personaLegal" [(ngModel)]="local_data.personaLegal">
                            <mat-radio-button style="margin-left: 10px;" color="primary" value="FISICA">
                                Física
                            </mat-radio-button>
                            <mat-radio-button style="margin-left: 10px;" color="primary" value="JURIDICA">
                                Jurídica
                            </mat-radio-button>
                        </mat-radio-group>
                    </section>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="20">
                <div class="m-r-15 m-l-15" *ngIf="local_data.ejerciente">
                    <mat-form-field>
                        <input type="number" matInput id="epg" name="epg" [(ngModel)]="local_data.epg" placeholder="E.P.Q.">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="20">
                <div class="m-r-15 m-l-15" *ngIf="local_data.personaLegal  === 'JURIDICA' && local_data.ejerciente">
                    <mat-form-field>
                        <input type="text" matInput id="razonSocial" name="razonSocial" [(ngModel)]="local_data.razonSocial" placeholder="Razón social">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="number" required matInput id="nTerritorial" name="nTerritorial" [(ngModel)]="local_data.nTerritorial" placeholder="Número Territorial">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="50">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="number" matInput id="nNacional" name="nNacional" [(ngModel)]="local_data.nNacional" placeholder="Número Nacional">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="70">
                <div class="m-r-15 m-l-15">
                    <label style="margin-left: 10px;">¿Le ha sido realizada alguna reclamación por negligencia, error u
                        omisión profesional en los últimos 5 años?:</label>
                    <mat-radio-group name="negligencia" [(ngModel)]="local_data.negligencia">
                        <mat-radio-button style="margin-left: 10px;" color="primary" [value]="true">
                            SÍ
                        </mat-radio-button>
                        <mat-radio-button style="margin-left: 10px;" color="primary" [value]="false">
                            NO
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="10">
                <div class="m-r-15 m-l-15" *ngIf="local_data.negligencia">
                    <mat-form-field>
                        <input type="number" matInput id="importeNegligencia" name="importeNegligencia" [(ngModel)]="local_data.importeNegligencia" placeholder="Importe">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="10">
                <div class="m-r-15 m-l-15" *ngIf="local_data.negligencia">
                    <mat-form-field>
                        <input type="number" matInput id="importeNegligencia" name="importeNegligencia" [(ngModel)]="local_data.importeNegRecservas" placeholder="Reservas">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="10">
                <div class="m-r-15 m-l-15" *ngIf="local_data.negligencia">
                    <mat-form-field>
                        <input type="number" matInput id="importeNegPagos" name="importeNegPagos" [(ngModel)]="local_data.importeNegPagos" placeholder="Pagos">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="70">
                <div class="m-r-15 m-l-15">
                    <label style="margin-left: 10px">¿Tiene conocimiento de cualquier circunstancia qeu razonablemnete
                        pudiera dar lugar a una reclamación?:</label>
                    <mat-radio-group name="futuraReclamacion" [(ngModel)]="local_data.futuraReclamacion">
                        <mat-radio-button style="margin-left: 10px;" color="primary" [value]="true">
                            SÍ
                        </mat-radio-button>
                        <mat-radio-button style="margin-left: 10px;" color="primary" [value]="false">
                            NO
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="30">
                <div class="m-r-15 m-l-15" *ngIf="local_data.futuraReclamacion">
                    <mat-form-field>
                        <input type="text" matInput id="siReclamacion" name="siReclamacion" [(ngModel)]="local_data.siReclamacion" placeholder="Breve descripción">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100">
                <h3>Número de cuenta para el pago de las cuotas</h3>
            </div>
            <div fxFlex="100" fxFlex.gt-md="40">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput id="titularCuenta" name="titularCuenta" [(ngModel)]="local_data.titularCuenta" placeholder="Titular de la cuenta">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="12">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput maxlength="4" id="iban" name="iban" [(ngModel)]="local_data.iban" placeholder="Etiqueta IBAN">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-md="28">
                <div class="m-r-15 m-l-15">
                    <mat-form-field>
                        <input type="text" matInput maxlength="20" id="nc" name="nc" [(ngModel)]="local_data.nc" placeholder="Número de cuenta">
                    </mat-form-field>
                </div>
            </div>

        </div>
        <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!userForm.valid">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </form>
</mat-dialog-content>
<ng-template #elseTemplate>
    <p>¿Esta seguro que desa eliminar a <b>{{local_data.nombre}}</b> ?</p>
    <div mat-dialog-actions align="end" class="pt-3">
        <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </div>
</ng-template>
