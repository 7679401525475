import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CursoResp, Curso } from '../../models/curso.model';

@Injectable({
  providedIn: 'root'
})
export class CursosService {

  constructor( private ds: DataService) { }

  getCursos(filter?: string) {
    return new Promise<CursoResp>(resolve => {
      this.ds.getData('cursos?filter?=' + filter).then(resp => resolve(resp));
    });
  }

  setOrUpdateCurso(obj: Curso) {
    return new Promise<CursoResp>(resolve => {
      this.ds.setData('cursos', obj).then(resp => resolve(resp));
    });
  }

  deleteCurso(id: string) {
    return new Promise<CursoResp>(resolve => {
        this.ds.delData('cursos/' + id).then(resp => resolve(resp));
    });
  }

  sendTest(id: string){
    return new Promise<any>(resolve => {
      this.ds.updateData('cursos/sendTest/' + id, {}).then(resp => resolve(resp));
    });
  }

  sendCurso(id: string){
    return new Promise<any>(resolve => {
      this.ds.updateData('cursos/send/' + id, {}).then(resp => resolve(resp));
    });
  }

  getAsitencia(id: string, filter?: string){
    return new Promise<any>(resolve => {
      this.ds.getData('cursos/asistencia/' + id + '?filter=' + filter).then(resp => resolve(resp));
    });
  }

  setAsistencia(asistencia: any) {
    return new Promise(resolve => {
      this.ds.updateData('cursos/asistencia/' + asistencia._id, {}).then(resp => resolve(resp));
    });
  }

  getAsistenciaColegiado(idColegiado?: string){
    return new Promise<any>(resolve => {
      this.ds.updateData('cursos/colegiado/', {idColegiado}).then(resp => resolve(resp));
    })
  }

  getCertificadoCurso(id: string){
    return new Promise<any>(resolve => {
      this.ds.updateData('cursos/solicitudCertificado/' + id, {}).then(resp => resolve(resp));
    });
  }
}
