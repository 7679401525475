import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MemoriaFormComponent } from '../../../formularios/memoria-form/memoria-form.component';
import { Memoria } from '../../../../models/memoria.model';
import { MemoriaService } from '../../../services/memoria.service';
import { Mailing } from '../../../../models/mailing.model';
import { UiService } from '../../../services/ui.service';

@Component({
  selector: 'app-memorias',
  templateUrl: './memorias.component.html',
  styles: []
})
export class MemoriasComponent implements OnInit {

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  pages: any [] = [0];
  pageEvent: any = {};
  displayedColumns: string[] = ['nombre', 'fecha', 'colegiados', 'docCirculares', 'docAnuncios', 'docIpc', 'docLegislacion', 'docFormacion', 'webinars', 'action'];
  dataSource: MatTableDataSource<Memoria>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  length: number = 0;


  constructor(private dialog: MatDialog,
              private memoriaService: MemoriaService,
              private ui: UiService) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getServerData();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(MemoriaFormComponent, {
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Generar') {
        this.setMemoria(result.data);
      } else if (result.event === 'Actualiza') {
        
        // this.updateRowData(result.data);
      } else if (result.event === 'Eliminar') {        
        this.deleteRowData(result.data);
      }
    });
  }

  async setMemoria(data: any){
    const memoria: Memoria = {
      nombre: data.nombre,
      anio: data.anio
    };
    const resp = await this.memoriaService.setMemoria(memoria);
    if (resp.error){
      window.alert(resp.error);
      return;
    }
    this.getServerData();
  }

  async applyFilter(filterValue: string) {}
  async getServerData(event?: PageEvent){
    const resp = await this.memoriaService.getMemorias();
    if (!resp.ok){
      window.alert(resp.error);
      return
    }
    this.dataSource.data = resp.memorias || [];
  }

  async deleteRowData(data: any) {
    const doc = await this.memoriaService.delMemoria(data._id);
    if (!doc.ok) {
      window.alert(doc.error);
      return;
    }
    this.dataSource.data = this.dataSource.data.filter((value: any) => {
      return value._id !== data._id;
    });
  }
}
