import { Component, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-mantenimiento',
  templateUrl: './mantenimiento.component.html',
  styles: []
})
export class MantenimientoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  descarga(tipo: string){
    if (tipo === 'guia'){
      console.log(tipo);
      window.saveAs('/Users/mbarbero/Documents/Proyectos Activos/CafSevilla/cafsevillafront/src/assets/doc/guia.pdf', 'guia.pdf');
    }
  }

}
