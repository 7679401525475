import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { ColegiadoService } from '../../services/colegiado.service';


@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  public form: FormGroup = Object.create(null);

  email = '';
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private colegiadoServioce: ColegiadoService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email])
      ]
    });
  }

  async onSubmit(){
    const ok = await this.colegiadoServioce.resetPass(this.email);
    if (!ok.ok){
      window.alert(ok.error);
      return;
    }
    this.router.navigate(['/login']);
  }
}
