import { Component, Optional, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Webinar } from '../../../models/webinar.model';
import Player from '@vimeo/player';


@Component({
  selector: 'app-webinar-visor',
  templateUrl: './webinar-visor.component.html',
  styles: []
})


export class WebinarVisorComponent implements OnInit{
  local_data: Webinar;

  constructor(public datePipe: DatePipe,
              public dialogRef: MatDialogRef<WebinarVisorComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: Webinar) { 
                this.local_data = {...data};                
              }
  
  ngOnInit(){
    
    if (this.local_data.video){
      const player = new Player('handstick', {
        id: Number(this.local_data.video),
        width: 640
      });
      player.on('play', function() {
        console.log('played the video!');
      });
    }  
  }
  cerrar(){
    this.dialogRef.close();
  }
}
