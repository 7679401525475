<h2 class="font-medium" mat-dialog-title>
    <strong>Buscar documento por fechas de publicación</strong></h2>
<mat-dialog-content>
    <form #form="ngForm">
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex.gt-md="48" fxFlex.gt-lg="48" fxFlex="100">
                <mat-form-field>
                    <input matInput [matDatepicker]="pickerFromIni" placeholder="Inicio..." name="pickerFromIni" [(ngModel)]="dateIni" required>
                    <mat-datepicker-toggle matSuffix [for]="pickerFromIni"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFromIni startView="year"></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxFlex.gt-md="4" fxFlex.gt-lg="4" fxFlex="100"></div>

            <div fxFlex.gt-md="48" fxFlex.gt-lg="48" fxFlex="100">
                <mat-form-field>
                    <input matInput [matDatepicker]="pickerFromFin" placeholder="Fin..." name="pickerFromFin" [(ngModel)]="dateFin">
                    <mat-datepicker-toggle matSuffix [for]="pickerFromFin"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFromFin startView="year"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <button mat-button (click)="doAction()" mat-flat-button color="warn" [disabled]="!form.valid">Buscar</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </form>
</mat-dialog-content>
