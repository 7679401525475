<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<label style="font-size: medium;">
    <label *ngIf="permiso !== 'COLEG'" style="color: grey;">({{permiso}})</label>
    {{nombre}}</label>
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <mat-icon>menu</mat-icon>
</button>

<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item (click)="miData()" *ngIf="permiso !== 'COLEG'">
        <mat-icon>account_box</mat-icon> Mis datos
    </button>
    <button mat-menu-item (click)="miData()" *ngIf="permiso == 'COLEG'">
        <mat-icon>account_box</mat-icon> Cambio de contraseña
    </button>
    <button mat-menu-item (click)="exit()">
        <mat-icon>exit_to_app</mat-icon> Salir
    </button>
</mat-menu>
